import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AxiosInterceptors from './utils/network-interceptors';

import store from './app/store';

import App from './app/App';
import { Bugsnag } from 'utils';

const ErrorBoundary = Bugsnag.getPlugin('react');

AxiosInterceptors.setup(store);

const render = () => {
  let BaseApp = () => (
    <Provider store={store}>
      <BrowserRouter basename="/#">
        <App />
      </BrowserRouter>
    </Provider>
  );

  ReactDOM.render(
    ErrorBoundary ? (
      <ErrorBoundary>
        <BaseApp />
      </ErrorBoundary>
    ) : (
      <BaseApp />
    ),
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
