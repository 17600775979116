import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import WorthyyAPI, { AdminDashboardResponse } from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface DashboardState {
  admins: number;
  users: number;
  leads: number;
  isLoading: boolean;
  errorMessage: string;
}

let initialState: DashboardState = {
  users: 0,
  admins: 0,
  leads: 0,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: DashboardState) {
  state.isLoading = true;
}

function loadingFailed(state: DashboardState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchDashboardStart: startLoading,

    fetchDashboardSuccess(
      state,
      { payload }: PayloadAction<AdminDashboardResponse>
    ) {
      const { admins, users, leads } = payload;
      state.admins = admins;
      state.users = users;
      state.leads = leads;
      state.isLoading = false;
    },

    fetchDashboardFailure: loadingFailed,
  },
});

export const {
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const fetchDashboard = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchDashboardStart());
    const data = await WorthyyAPI.admin.dashboard.show();
    dispatch(fetchDashboardSuccess(data));
  } catch (err) {
    dispatch(fetchDashboardFailure(err.toString()));
  }
};

// selectors

export const selectDashboardData = createSelector(
  (state: RootState) => state.dashboard,
  data => data
);

export const selectDashboardLoading = createSelector(
  (state: RootState) => state.dashboard.isLoading,
  bool => bool
);
