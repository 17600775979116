import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectIsAuthenticated,
  hydrateUser,
  hydrateAdmin,
} from 'features/auth/authSlice';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [resolved, setResolved] = useState(false);
  const dispatch = useDispatch();
  let isAdmin = false;
  let loginRoute = '/login';
  if (location.pathname.includes('admin')) {
    loginRoute = `/admin${loginRoute}`;
    isAdmin = true;
  }

  useEffect(() => {
    // let a user refresh a page without it being annoying. we if there is a token and they're not already authenticated, try to use it.
    const checkUser = async () => {
      if (isAdmin) {
        return dispatch(hydrateAdmin());
      } else {
        return dispatch(hydrateUser());
      }
    };

    if (
      !isAuthenticated &&
      (localStorage.getItem('access_token') ||
        sessionStorage.getItem('access_token'))
    ) {
      checkUser().finally(() => {
        setResolved(true);
      });
    } else {
      setResolved(true);
    }
  }, [isAuthenticated, dispatch, isAdmin]);

  return !resolved ? null : (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: loginRoute,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
