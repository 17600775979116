import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk, AsyncThunk } from 'app/store';
import WorthyyAPI, {
  AdminsResponse,
  Admin,
  AdminSignInResponse,
  AccountMessage,
  AdminDeleteResponse,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface AdminsState {
  item: Admin;
  accountMessages: AccountMessage[];
  items: Admin[];
  itemsById: { [key: string]: Admin };
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

interface ById {
  [key: string]: any;
}
let initialState: AdminsState = {
  item: {} as Admin,
  accountMessages: [],
  items: [],
  itemsById: {},
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: AdminsState) {
  state.isLoading = true;
}

function loadingFailed(state: AdminsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    fetchAdminsStart: startLoading,
    fetchAdminStart: startLoading,
    updateAdminStart: startLoading,
    createAdminStart: startLoading,
    deleteAdminStart: startLoading,
    setAdminPasswordStart: startLoading,

    fetchAdminsSuccess(state, { payload }: PayloadAction<AdminsResponse>) {
      const { data } = payload;
      state.items = data;
      state.itemsById = data.reduce((acc: ById, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      state.isFetched = true;
      state.isLoading = false;
      state.errorMessage = '';
    },

    fetchAdminSuccess(state, { payload }: PayloadAction<AdminSignInResponse>) {
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
      state.accountMessages = data.accountMessages.data;
      state.errorMessage = '';
    },

    updateAdminSuccess(state, { payload }: PayloadAction<AdminSignInResponse>) {
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setAdminPasswordSuccess(
      state,
      { payload }: PayloadAction<AdminSignInResponse>
    ) {
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
      state.errorMessage = '';
    },

    createAdminSuccess(state, { payload }: PayloadAction<AdminSignInResponse>) {
      const { data } = payload;
      state.items.push(data);
      state.itemsById[data.id] = data;
      state.isLoading = false;
      state.errorMessage = '';
    },
    deleteAdminSuccess(state, action: PayloadAction<AdminDeleteResponse>) {
      state.isLoading = false;
      state.errorMessage = '';
    },

    fetchAdminsFailure: loadingFailed,
    fetchAdminFailure: loadingFailed,
    updateAdminFailure: loadingFailed,
    createAdminFailure: loadingFailed,
    deleteAdminFailure: loadingFailed,
    setAdminPasswordFailure: loadingFailed,
  },
});

export const {
  fetchAdminsStart,
  fetchAdminsSuccess,
  fetchAdminsFailure,
  fetchAdminStart,
  fetchAdminSuccess,
  fetchAdminFailure,
  updateAdminStart,
  updateAdminSuccess,
  updateAdminFailure,
  createAdminStart,
  createAdminSuccess,
  createAdminFailure,
  deleteAdminStart,
  deleteAdminSuccess,
  deleteAdminFailure,
  setAdminPasswordStart,
  setAdminPasswordSuccess,
  setAdminPasswordFailure,
} = adminsSlice.actions;

export default adminsSlice.reducer;

export const fetchAdmins = (
  search?: any
): AsyncThunk<AdminsResponse> => async dispatch => {
  try {
    dispatch(fetchAdminsStart());
    const data = await WorthyyAPI.admin.search(search);
    return dispatch(fetchAdminsSuccess(data));
  } catch (err) {
    dispatch(fetchAdminsFailure(err.toString()));
    throw err;
  }
};

export const fetchAdmin = (id: number): AppThunk => async dispatch => {
  try {
    dispatch(fetchAdminStart());
    const data = await WorthyyAPI.admin.show(id);
    return dispatch(fetchAdminSuccess(data));
  } catch (err) {
    dispatch(fetchAdminFailure(err.toString()));
    throw err;
  }
};

export const updateAdmin = (
  adminId: number | string,
  fields: Partial<Admin>
): AppThunk => async dispatch => {
  try {
    dispatch(updateAdminStart());
    const admin = await WorthyyAPI.admin.update(adminId, fields);
    return dispatch(updateAdminSuccess(admin));
  } catch (err) {
    dispatch(updateAdminFailure(err.toString()));
    throw err;
  }
};

export const setAdminPassword = (
  adminId: number | string,
  newPassword: string
): AppThunk => async dispatch => {
  try {
    dispatch(updateAdminStart());
    const admin = await WorthyyAPI.admin.update(adminId, {
      new_password: newPassword,
    });
    return dispatch(updateAdminSuccess(admin));
  } catch (err) {
    dispatch(updateAdminFailure(err.toString()));
    throw err;
  }
};

export const createAdmin = (
  fields: Partial<Admin>
): AppThunk<Promise<PayloadAction<AdminSignInResponse>>> => async dispatch => {
  try {
    dispatch(createAdminStart());
    const admin = await WorthyyAPI.admin.create(fields);
    return dispatch(createAdminSuccess(admin));
  } catch (err) {
    dispatch(createAdminFailure(err.toString()));
    throw err;
  }
};

export const deleteAdmin = (
  adminId: number | string
): AppThunk => async dispatch => {
  try {
    dispatch(deleteAdminStart());
    const admin = await WorthyyAPI.admin.delete(adminId);
    return dispatch(deleteAdminSuccess(admin));
  } catch (err) {
    dispatch(deleteAdminFailure(err.toString()));
    throw err;
  }
};

// selectors

export const selectAdmin = createSelector(
  (state: RootState) => state.admins.item,
  user => user
);
export const selectAdmins = createSelector(
  (state: RootState) => state.admins.items,
  admins => admins
);

export const selectAdminsById = createSelector(
  (state: RootState) => state.admins.itemsById,
  byId => byId
);

export const selectAdminsLoading = createSelector(
  (state: RootState) => state.admins.isLoading,
  bool => bool
);

export const selectAdminsErrorMessage = createSelector(
  (state: RootState) => state.admins.errorMessage,
  message => message
);

export const selectAdminAccountMessages = createSelector(
  (state: RootState) => state.admins.accountMessages,
  message => message
);
