import React from 'react';
import { Formik, Form, FormikConfig, Field } from 'formik';
import { makeStyles, Grid } from '@material-ui/core';
import ButtonLoading from '../../components/ButtonLoading';
import FormikNumberFormat from 'components/FormikNumberFormat';
import { paymentMethodSchema } from 'features/payments/payments.schemas';

export interface CreditCardFormValues {
  number: string;
  exp_date: string;
  cvv: string;
  zip: string;
}

interface Props extends FormikConfig<CreditCardFormValues> {
  isLoading: boolean;
  errorMessage?: string | '';
  disabled: boolean;
  buttonText?: string;
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));

export const CreditCardFields = ({ isLoading = false }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={8}>
      <Field
        required
        material
        name="number"
        label="Card number"
        type="tel"
        placeholder="xxxx xxxx xxxx xxxx"
        format="#### #### #### ####"
        disabled={isLoading}
        component={FormikNumberFormat}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        required
        material
        name="cvv"
        label="CVV"
        type="tel"
        placeholder="Code"
        format="####"
        disabled={isLoading}
        component={FormikNumberFormat}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>

    <Grid item xs={12} sm={8}>
      <Field
        material
        required
        name="exp_date"
        type="tel"
        label="Expiration Date"
        placeholder="MM / YYYY"
        format="##/####"
        disabled={isLoading}
        component={FormikNumberFormat}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <Field
        material
        name="zip"
        label="Zip"
        type="tel"
        format="#####"
        placeholder="Postal Code"
        disabled={isLoading}
        component={FormikNumberFormat}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </Grid>
);
export const CreditCardForm: React.FC<Props> = ({
  disabled = true,
  isLoading = false,
  errorMessage = null,
  buttonText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Formik
      {...props}
      enableReinitialize
      onSubmit={async (values, formikHelpers) => {
        await props.onSubmit(values, formikHelpers);
        formikHelpers.setSubmitting(false);
        formikHelpers.resetForm();
      }}
      validationSchema={paymentMethodSchema}
    >
      <Form className={classes.form} style={{ marginBottom: 25 }}>
        <CreditCardFields isLoading={isLoading} />
        {/* <AlertMessage>{errorMessage}</AlertMessage> */}
        <Grid container>
          <ButtonLoading
            className={classes.submit}
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            {buttonText || 'SAVE'}
          </ButtonLoading>
        </Grid>
      </Form>
    </Formik>
  );
};
