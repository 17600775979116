const {
  REACT_APP_API_URI,
  REACT_APP_BUGSNAG_ENABLED,
  REACT_APP_BUGSNAG_API_KEY,
  REACT_APP_BUGSNAG_RELEASE_STAGE,
  REACT_APP_LOGROCKET_DOMAIN,
  REACT_APP_LOGROCKET_ENABLED,
} = process.env;

export const API_URI = REACT_APP_API_URI;

export const BUGSNAG_ENABLED = REACT_APP_BUGSNAG_ENABLED;
export const BUGSNAG_API_KEY = REACT_APP_BUGSNAG_API_KEY;
export const BUGSNAG_RELEASE_STAGE = REACT_APP_BUGSNAG_RELEASE_STAGE;

export const LOGROCKET_DOMAIN = REACT_APP_LOGROCKET_DOMAIN;
export const LOGROCKET_ENABLED = REACT_APP_LOGROCKET_ENABLED;
