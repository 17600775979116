import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk, AsyncThunk } from 'app/store';
import WorthyyAPI, { Coupon, ValidateCouponResponse } from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface PromoCodesState {
  item: Coupon;
  items: Coupon[];
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

let initialState: PromoCodesState = {
  item: {} as Coupon,
  items: [],
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: PromoCodesState) {
  state.isLoading = true;
}

function loadingFailed(state: PromoCodesState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    fetchPromoCodesStart: startLoading,
    validatePromoCodeStart: startLoading,

    fetchPromoCodesSuccess(
      state,
      { payload }: PayloadAction<{ promo_codes: Coupon[] }>
    ) {
      state.items = payload.promo_codes.filter(
        code => code.archived_at === null
      );
      state.isFetched = true;
      state.isLoading = false;
    },

    validatePromoCodeSuccess(
      state,
      { payload }: PayloadAction<ValidateCouponResponse>
    ) {
      state.item = payload.promo_code;
      state.isLoading = false;
    },

    fetchPromoCodesFailure: loadingFailed,
    validatePromoCodeFailure: loadingFailed,
  },
});

export const {
  fetchPromoCodesStart,
  fetchPromoCodesSuccess,
  fetchPromoCodesFailure,
  validatePromoCodeStart,
  validatePromoCodeSuccess,
  validatePromoCodeFailure,
} = promoCodesSlice.actions;

export default promoCodesSlice.reducer;

export const fetchPromoCodes = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchPromoCodesStart());
    const data = await WorthyyAPI.admin.promoCodes.list();
    dispatch(fetchPromoCodesSuccess(data));
  } catch (err) {
    dispatch(fetchPromoCodesFailure(err.toString()));
  }
};

export const validatePromoCodeSelf = (
  code: string
): AsyncThunk<ValidateCouponResponse> => async dispatch => {
  try {
    dispatch(validatePromoCodeStart());
    const data = await WorthyyAPI.user.subscription.validatePromoCode(code);
    return dispatch(validatePromoCodeSuccess(data));
  } catch (err) {
    dispatch(validatePromoCodeFailure(err.toString()));
    throw err;
  }
};
// selectors

export const selectPromoCodes = createSelector(
  (state: RootState) => state.promoCodes.items,
  items => items
);

export const selectPromoCodesLoading = createSelector(
  (state: RootState) => state.promoCodes.isLoading,
  bool => bool
);

export const selectPromoCodesFetched = createSelector(
  (state: RootState) => state.promoCodes.isFetched,
  bool => bool
);

export const selectPromoCodesAsAutocompleteOptions = createSelector(
  (state: RootState) => state.promoCodes.items,
  promoCodes => {
    const result = [];

    for (const promoCode of promoCodes) {
      if (promoCode.code) {
        const discount = promoCode.amount_in_cents
          ? `$${(promoCode.amount_in_cents / 100).toFixed(2)}`
          : `${Math.round(Number(promoCode.percentage))}%`;

        result.push({
          label: `${promoCode.code} | Discount: ${discount}`,
          value: promoCode.code,
        });
      }
    }

    return [{ label: 'None', value: null }, ...result];
  }
);
