import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { AsyncThunk } from 'app/store';
import WorthyyAPI, {
  AdminAuthorization,
  AddAuthorizationResponse,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export const adapter = createEntityAdapter<AdminAuthorization>();
const initialState = adapter.getInitialState({
  errorMessage: '',
  loading: false,
  fetched: false,
});

export type AuthorizationsState = typeof initialState;
function startLoading(state: AuthorizationsState) {
  state.loading = true;
}

function loadingFailed(
  state: AuthorizationsState,
  action: PayloadAction<string>
) {
  state.loading = false;
  state.errorMessage = action.payload;
}

const authorizationsSlice = createSlice({
  name: 'authorizations',
  initialState,
  reducers: {
    fetchAuthorizationsStart: startLoading,
    addAuthorizationStart: startLoading,
    removeAuthorizationStart: startLoading,

    fetchAuthorizationsSuccess(state, { payload }: PayloadAction<any>) {
      const { admin_authorizations } = payload;

      adapter.upsertMany(state, admin_authorizations);
      state.loading = false;
      state.errorMessage = '';
    },

    addAuthorizationSuccess(
      state,
      { payload }: PayloadAction<AddAuthorizationResponse>
    ) {
      adapter.upsertOne(state, payload.admin_authorization);
    },

    removeAuthorizationSuccess(
      state,
      { payload }: PayloadAction<{ id: number }>
    ) {
      adapter.removeOne(state, payload.id);
    },

    fetchAuthorizationsFailure: loadingFailed,
    addAuthorizationFailure: loadingFailed,
    removeAuthorizationFailure: loadingFailed,
  },
});

export const {
  fetchAuthorizationsStart,
  fetchAuthorizationsSuccess,
  fetchAuthorizationsFailure,
  addAuthorizationStart,
  addAuthorizationSuccess,
  addAuthorizationFailure,
  removeAuthorizationStart,
  removeAuthorizationSuccess,
  removeAuthorizationFailure,
} = authorizationsSlice.actions;

export default authorizationsSlice.reducer;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors((state: RootState) => state.authorizations);

export const selectAuthorizationIds = (state: RootState) => selectIds(state);
export const selectAuthorizationEntities = (state: RootState) =>
  selectEntities(state);
export const selectAllAuthorizations = (state: RootState) => selectAll(state);
export const selectTotalAuthorizations = (state: RootState) =>
  selectTotal(state);

export const selectAuthorizationsByAdminId = (adminId: number) =>
  createSelector(selectAllAuthorizations, authorizations =>
    authorizations.filter(auth => Number(auth.admin_id) === Number(adminId))
  );

export const fetchAuthorizations = (
  adminId: string | number
): AsyncThunk => async dispatch => {
  try {
    dispatch(fetchAuthorizationsStart());
    const data = await WorthyyAPI.admin.authorizations.list(adminId);
    return dispatch(fetchAuthorizationsSuccess(data));
  } catch (err) {
    dispatch(fetchAuthorizationsFailure(err.toString()));
    throw err;
  }
};

export const addAuthorization = (
  adminId: number | string,
  identifier: string
): AsyncThunk => async dispatch => {
  try {
    dispatch(addAuthorizationStart());
    const data = await WorthyyAPI.admin.authorizations.add(adminId, identifier);
    return dispatch(addAuthorizationSuccess(data));
  } catch (err) {
    dispatch(addAuthorizationFailure(err.toString()));
    throw err;
  }
};

export const removeAuthorization = (
  adminId: number | string,
  authorizationId: number
): AsyncThunk => async dispatch => {
  try {
    dispatch(removeAuthorizationStart());
    await WorthyyAPI.admin.authorizations.delete(adminId, authorizationId);
    return dispatch(removeAuthorizationSuccess({ id: authorizationId }));
  } catch (err) {
    dispatch(removeAuthorizationFailure(err.toString()));
    throw err;
  }
};
