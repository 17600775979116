import axios, { AxiosRequestConfig } from 'axios';
import keys from 'lodash/keys';
import isUndefined from 'lodash/isUndefined';

import { API_URI } from '../config';

function config(request: any) {
  request = request || { auth: true };
  if (isUndefined(request.auth)) {
    request.auth = true;
  }
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
  };

  let withCredentials = false;
  if (request.auth) {
    withCredentials = true;
    const token =
      sessionStorage.getItem('access_token') ||
      localStorage.getItem('access_token');
    headers.authorization = `Bearer ${token}`;
  }

  return { headers, withCredentials };
}

const setSearchParams = (searchParams: any, values: any) => {
  keys(values).forEach((key: string) => {
    const value = values[key];
    if (!isUndefined(value)) {
      searchParams.set(key, value);
    }
  });
};

const buildURL = (path: string, request: any) => {
  const url = new URL(`${API_URI}/${path}`, window.location.href);
  request = request || {};
  if (request.search) {
    const { search } = request;
    setSearchParams(url.searchParams, search);
  }
  if (request.query) {
    setSearchParams(url.searchParams, request.query);
  }

  return String(url);
};

export default class apiRequest {
  static get<T>(path: string, request: any = {}) {
    const url = buildURL(path, request);
    return axios.get<T>(url, config(request));
  }

  static post<T>(path: string, payload: object, request?: any) {
    const url = buildURL(path, request);
    return axios.post<T>(url, payload, config(request));
  }

  static put<T>(path: string, payload: object, request?: any) {
    const url = buildURL(path, request);
    return axios.put<T>(url, payload, config(request));
  }

  static delete<T>(path: string, request?: any) {
    const url = buildURL(path, request);
    return axios.delete<T>(url, config(request));
  }
}
