import apiRequest from './apiRequest';

const admin = {
  async search(search?: any) {
    try {
      const { data } = await apiRequest.get<AdminsResponse>(`admin/admins`, {
        search,
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
  async show(adminId: number) {
    try {
      const { data } = await apiRequest.get<AdminSignInResponse>(
        `admin/admins/${adminId}`
      );
      return data;
    } catch (err) {
      throw err;
    }
  },
  async signIn(email: string, password: string) {
    const { data: admin } = await apiRequest.post<AdminSignInResponse>(
      `admin/token`,
      { email, password },
      { auth: false }
    );
    return admin;
  },
  async hydrate() {
    try {
      const { data } = await apiRequest.get<AdminSignInResponse>(`admin/me`);
      return data;
    } catch (err) {
      throw err;
    }
  },
  async updateMe(fields: Partial<Admin & { new_password: string }>) {
    try {
      const { data } = await apiRequest.put<AdminUpdateResponse>(`admin/me`, {
        ...fields,
      });
      return data;
    } catch (err) {
      throw err;
    }
  },

  async update(
    adminId: number | string,
    fields: Partial<Admin & { new_password: string }>
  ) {
    try {
      const { data } = await apiRequest.put<AdminSignInResponse>(
        `admin/admins/${adminId}`,
        {
          ...fields,
        }
      );
      return data;
    } catch (err) {
      throw err;
    }
  },

  async delete(adminId: number | string) {
    try {
      const { data } = await apiRequest.delete<AdminDeleteResponse>(
        `admin/admins/${adminId}`
      );
      return data;
    } catch (err) {
      throw err;
    }
  },

  async create(fields: Partial<Admin & { new_password: string }>) {
    try {
      const { data } = await apiRequest.post<AdminSignInResponse>(
        `admin/admins/`,
        {
          ...fields,
        }
      );
      return data;
    } catch (err) {
      throw err;
    }
  },

  /*
GET /admin/billing/admins/{admin}/authorizations
POST /admin/billing/admins/{admin}/authorizations { grant_type: 'salesforce', identifier }
GET /admin/billing/admins/{admin}/authorizations/{authorization}
PUT /admin/billing/admins/{admin}/authorizations/{authorization} { identifier }
DELETE /admin/billing/admins/{admin}/authorizations/{authorization} (edited) 
  */
  authorizations: {
    async list(adminId: string | number) {
      const { data } = await apiRequest.get<AuthorizationsResponse>(
        `admin/billing/admins/${adminId}/authorizations`
      );
      return data;
    },
    /*
     * @identifier - salesforce user id
     */
    async add(adminId: string | number, identifier: string) {
      const { data } = await apiRequest.post<AddAuthorizationResponse>(
        `admin/billing/admins/${adminId}/authorizations`,
        { grant_type: 'salesforce', identifier }
      );
      return data;
    },
    async show(adminId: string | number, authorizationId: string) {
      const { data } = await apiRequest.get<any>(
        `admin/billing/admins/${adminId}/authorizations/${authorizationId}`
      );
      return data;
    },
    async update(
      adminId: string | number,
      authorizationId: number,
      identifier: string
    ) {
      const { data } = await apiRequest.post<any>(
        `admin/billing/admins/${adminId}/authorizations/${authorizationId}`,
        { identifier }
      );
      return data;
    },
    async delete(adminId: string | number, authorizationId: number) {
      const { data } = await apiRequest.delete<any>(
        `admin/billing/admins/${adminId}/authorizations/${authorizationId}`
      );
      return data;
    },
  },
  salesforce: {
    async listUsers() {
      const { data } = await apiRequest.get<SalesforceUsersResponse>(
        `admin/billing/salesforce/users`
      );
      return data;
    },
  },
  password: {
    async reset(email: string) {
      const { data } = await apiRequest.post<any>(
        `admin/forgot`,
        {
          email,
        },
        { auth: false }
      );
      return data;
    },
    async setWithToken(new_password: string, token: string) {
      const { data } = await apiRequest.post<any>(
        `admin/reset`,
        {
          new_password,
          token,
        },
        { auth: false }
      );
      return data;
    },
  },
  dashboard: {
    async show() {
      const { data } = await apiRequest.get<AdminDashboardResponse>(
        `admin/dashboard`
      );
      return data;
    },
  },
  payments: {
    async search(searchOpts: any = {}) {
      const { data } = await apiRequest.get<PaymentsResponse>(
        `admin/payments`,
        searchOpts
      );
      return data;
    },

    async findByUserId(id: number) {
      const { data } = await apiRequest.get<Promise<ChargifyPaymentsResponse>>(
        `admin/billing/users/${id}/payments`
      );
      return data;
    },
    async show(id: number) {
      const { data } = await apiRequest.get<PaymentsResponse>(
        `admin/payments/${id}`
      );
      return data;
    },
  },

  users: {
    async search(search?: any) {
      const { data } = await apiRequest.get<UsersResponse>(`admin/users`, {
        search,
      });
      return data;
    },

    async findById(id: number | string) {
      const { data } = await apiRequest.get<UserSignInResponse>(
        `admin/users/${id}`
      );
      return data;
    },
    async update(
      userId: number | string,
      fields: Partial<User> & { new_password?: string }
    ) {
      const { data } = await apiRequest.put<UserResponse>(
        `admin/billing/users/${userId}`,
        {
          ...fields,
        }
      );
      return data;
    },
    async setPassword(userId: number | string, password: string) {
      const { data } = await apiRequest.put<UserSignInResponse>(
        `admin/users/${userId}`,
        {
          new_password: password,
        }
      );
      return data;
    },
    async showTracks(userId: number | string) {
      const { data } = await apiRequest.get<TracksResponse>(
        `admin/users/${userId}/tracks`
      );
      return data;
    },
    async syncWithSF(userId: number | string) {
      const { data } = await apiRequest.post<UserSignInResponse>(
        `admin/billing/users/${userId}/sync`,
        {}
      );
      return data;
    },
    async getLoginToken(userId: number | string) {
      const { data } = await apiRequest.post<LoginAsUserResponse>(
        `admin/users/${userId}/token`,
        {}
      );
      return data;
    },
    async delete(userId: number | string): Promise<UserDeleteResponse> {
      const { data } = await apiRequest.delete<UserDeleteResponse>(
        `admin/users/${userId}`
      );
      return data;
    },
  },

  promoCodes: {
    async list() {
      const { data } = await apiRequest.get<PromoCodesResponse>(
        `admin/billing/promo_codes`
      );
      return data;
    },
  },

  pricePoints: {
    async list() {
      const { data } = await apiRequest.get<ProductPricePointResponse>(
        `admin/billing/product_price_points`
      );
      return data;
    },
  },

  enrollments: {
    async send(userId: number | string) {
      const { data } = await apiRequest.post(
        `admin/users/${userId}/enrollment/send`,
        {}
      );
      return data;
    },
  },

  subscriptions: {
    async show(userId: number | string) {
      const { data } = await apiRequest.get<
        ChargifySubscriptionWithUserResponse
      >(`admin/billing/users/${userId}/subscription`);
      return data;
    },
    async reactivate(userId: number, date?: string | null) {
      const { data } = await apiRequest.post<ChargifySubscriptionResponse>(
        `admin/billing/users/${userId}/subscription/reactivate`,
        {
          ...(date ? { next_payment_date: date } : {}),
        }
      );
      return data;
    },
    async retryPayment(userId: number) {
      const { data } = await apiRequest.post<ChargifySubscriptionResponse>(
        `admin/billing/users/${userId}/subscription/retry`,
        {}
      );
      return data;
    },
    async create(userId: number, values: CreateSubscriptionValues) {
      const { data } = await apiRequest.post<
        Promise<ChargifySubscriptionResponse>
      >(`admin/billing/users/${userId}/subscription`, values);
      return data;
    },
    async cancel(userId: number) {
      const { data } = await apiRequest.delete<SubscriptionResponse>(
        `admin/users/${userId}/subscription`,
        {}
      );
      return data;
    },
    async updatePaymentMethod(
      userId: number,
      cardDetails: UpdateCreditCardValues
    ) {
      // this is using worthyy...
      // const { data } = await apiRequest.put<any>(`admin/users/${userId}`, {
      //   cc_number: cardDetails.number,
      //   cc_month: cardDetails.exp_month,
      //   cc_year: cardDetails.exp_year,
      //   cc_cvv: cardDetails.cvv,
      //   cc_zip: cardDetails.zip,
      // });
      const { data } = await apiRequest.post<ChargifyPaymentMethodResponse>(
        `admin/billing/users/${userId}/payment_methods`,
        {
          number: cardDetails.number,
          exp_month: cardDetails.exp_month,
          exp_year: cardDetails.exp_year,
          cvv: cardDetails.cvv,
          zip: cardDetails.zip,
          current: true,
        }
      );
      return data;
    },
    async setPaymentDate(userId: number, date: Date) {
      const { data } = await apiRequest.put<ChargifySubscriptionResponse>(
        `admin/billing/users/${userId}/subscription`,
        {
          next_payment_date: date,
        }
      );
      return data;
    },
    async setPromoCode(userId: number, promo_code: string) {
      const { data } = await apiRequest.put<{
        user: User;
        subscription: ChargifySubscription;
      }>(`admin/billing/users/${userId}/subscription/promo_code`, {
        promo_code,
      });
      return data;
    },
    async removePromoCode(userId: number) {
      const { data } = await apiRequest.delete<{
        user: User;
        subscription: ChargifySubscription;
      }>(`admin/billing/users/${userId}/subscription/promo_code`);
      return data;
    },
  },
};

const user = {
  async signIn(email: string, password: string) {
    const { data: user } = await apiRequest.post<TokenResponse>(
      `token`,
      {
        email,
        password,
      },
      { auth: false }
    );
    return user;
  },
  async hydrate() {
    const { data } = await apiRequest.get<UserSignInResponse>(`me`);
    return data;
  },

  async update(fields: Partial<User & { new_password: string }>) {
    const { data } = await apiRequest.put<UserUpdateSelfResponse>(`me`, {
      ...fields,
    });
    return data;
  },

  enrollment: {
    async findByToken(token: string) {
      const { data } = await apiRequest.get<EnrollmentResponse>(
        `/enrollment/?hash=${token}`
      );
      return data;
    },
    async consume(options: ConsumeEnrollmentOptions) {
      const { data } = await apiRequest.post<any>(`/enrollment`, options);
      return data;
    },
  },

  mx: {
    async getLink() {
      const { data } = await apiRequest.get<any>(`mx/master_widget`);
      return data;
    },
  },

  benefithub: {
    async getLink() {
      const { data } = await apiRequest.get<any>(`sso/benefithub`);
      return data;
    },
  },

  subscription: {
    async updatePaymentMethod({
      number,
      exp_month,
      exp_year,
      cvv,
      zip,
    }: UpdateCreditCardValues) {
      const { data } = await apiRequest.post<ChargifyPaymentMethodResponse>(
        `billing/payment_methods`,
        {
          number,
          exp_month,
          exp_year,
          cvv,
          zip,
          current: true,
        }
      );
      return data;
    },
    async show() {
      const { data } = await apiRequest.get<
        ChargifySubscriptionWithUserResponse
      >(`billing/subscription`);
      return data;
    },
    async retryPayment() {
      const { data } = await apiRequest.post<ChargifySubscriptionResponse>(
        `billing/subscription/retry`,
        {}
      );
      return data;
    },
    async create(values: CreateSubscriptionValues) {
      const { data } = await apiRequest.post<ChargifySubscriptionResponse>(
        `billing/subscription`,
        values
      );
      return data;
    },
    async validatePromoCode(promo_code: string) {
      const { data } = await apiRequest.post<ValidateCouponResponse>(
        `billing/subscription/promo_code/valid`,
        {
          promo_code,
        }
      );
      return data;
    },
  },

  payments: {
    async list() {
      const { data } = await apiRequest.get<ChargifyPaymentsResponse>(
        `billing/payments`
      );
      return data;
    },
  },

  password: {
    async update(newPassword: string) {
      const { data } = await apiRequest.put<any>(`me`, {
        new_password: newPassword,
      });
      return data;
    },
    async reset(email: string) {
      const { data } = await apiRequest.post<any>(
        `forgot`,
        {
          email,
        },
        { auth: false }
      );
      return data;
    },
    async setWithToken(new_password: string, token: string) {
      const { data } = await apiRequest.post<any>(
        `reset`,
        {
          new_password,
          token,
        },
        { auth: false }
      );
      return data;
    },
  },
};

export default {
  user,
  admin,
};

export interface ConsumeEnrollmentOptions {
  enrollmentHash: string;
  password: string;
  cc_number?: string;
  cc_month?: string;
  cc_year?: string;
  cc_cvv?: string;
  cc_zip?: string;
}
export interface CreateSubscriptionValues {
  payment_method: {
    number: string;
    exp_year: string;
    exp_month: string;
    cvv: string;
    zip: string;
  };
  amount: string;
  start_date?: string;
  promo_code?: string;
  [key: string]: any;
}

export interface SalesforceUserAttributes {
  type: string;
  url: string;
}

export interface SalesforceUser {
  attributes: SalesforceUserAttributes;
  Id: string;
  Alias: string;
  Username: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MobilePhone: string;
  UserType: string;
  CreatedDate: Date;
  LastModifiedDate: Date;
}

export interface SalesforceUsersResponse {
  User: SalesforceUser[];
}

export interface Link {
  rel: string;
  uri: string;
}

export interface Variables {
  hostname: string;
  token: string;
  path: string;
}

export interface AccountMessage {
  id: number;
  user_id?: any;
  lead_id?: any;
  admin_id: number;
  job_id?: any;
  admin_sender_id?: any;
  status: string;
  message_type: string;
  to: string;
  subject: string;
  template_name: string;
  response?: any;
  variables: Variables;
  created_at: Date;
  updated_at: Date;
  provider: string;
  provider_message_id?: any;
  links: any[];
  [key: string]: any;
}

export interface AccountMessages {
  success: boolean;
  data: AccountMessage[];
}

export interface LoginAsUserResponse {
  status: boolean;
  data: {
    token: string;
  };
}

export interface Admin {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role: string;
  status: string;
  permissions: string;
  locked: string;
  password: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  reset_token: string;
  links: Link[];
  accountMessages: AccountMessages;
}

export interface PermissibleLink {
  url: string;
  method: string;
}

export interface PermissibleLinks {
  [key: string]: PermissibleLink;
}

export interface AdminSignInResponse {
  success: boolean;
  data: Admin;
  meta: Meta;
}

export interface AdminUpdateResponse {
  success: boolean;
  msg: string;
  data: Admin;
}

export interface UserUpdateResponse {
  success: boolean;
  msg: string;
  data: User;
}
export interface UserUpdateSelfResponse {
  success: boolean;
  msg: string;
  user: User;
}
export interface AdminDeleteResponse {
  success: boolean;
  msg: string;
}

export interface UserDeleteResponse {
  success: boolean;
  msg: string;
}

export interface AdminsResponse {
  success: boolean;
  data: Admin[];
  meta: Meta;
}

export interface Payment {
  id: number;
  user_id: number;
  subscription_id: number;
  result: string;
  error_reviewed: number;
  amount_charged: number;
  full_amount: number;
  confirmation_code: string;
  failed_reason: string;
  parent_id: number;
  created_at: Date;
  updated_at: Date;
  authnet_transid: string;
  authnet_status: string;
  authnet_reason_code?: any;
  authnet_authcode?: any;
  authnet_account_number: string;
  authnet_paynum: number;
  authnet_submitted_at: Date;
  authnet_settled_at: Date;
  payment_type: string;
  previous_user_status: string;
  batch_id: number;
  links: any[];
}

export interface Payments {
  success: boolean;
  data: Payment[];
}

export interface Notifications {
  success: boolean;
  data: any[];
}

export interface Subscription {
  id: number;
  amount: number;
  start_date: Date;
  next_date: string;
  status: string;
  chargify: ChargifySubscription;
  links: any[];
}
export interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  organization?: any;
  email: string;
  created_at: Date;
  updated_at: Date;
  reference?: any;
  address?: any;
  address_2?: any;
  city?: any;
  state?: any;
  zip?: any;
  country?: any;
  phone?: any;
  portal_invite_last_sent_at?: any;
  portal_invite_last_accepted_at?: any;
  verified: boolean;
  portal_customer_created_at?: any;
  vat_number?: any;
  cc_emails?: any;
  tax_exempt: boolean;
  parent_id?: any;
  locale?: any;
}

export interface ProductFamily {
  id: number;
  name: string;
  description: string;
  handle: string;
  accounting_code?: any;
}

export interface PublicSignupPage {
  id: number;
  return_url?: any;
  return_params: string;
  url: string;
}

export interface Product {
  id: number;
  name: string;
  handle: string;
  description: string;
  accounting_code: string;
  request_credit_card: boolean;
  expiration_interval?: any;
  expiration_interval_unit: string;
  created_at: Date;
  updated_at: Date;
  price_in_cents: number;
  interval: number;
  interval_unit: string;
  initial_charge_in_cents?: any;
  trial_price_in_cents?: any;
  trial_interval?: any;
  trial_interval_unit: string;
  archived_at?: any;
  require_credit_card: boolean;
  return_params: string;
  taxable: boolean;
  update_return_url: string;
  tax_code: string;
  initial_charge_after_trial: boolean;
  version_number: number;
  update_return_params: string;
  default_product_price_point_id: number;
  product_price_point_id: number;
  product_price_point_handle: string;
  product_family: ProductFamily;
  public_signup_pages: PublicSignupPage[];
}

export interface CreditCard {
  id: number;
  first_name: string;
  last_name: string;
  masked_card_number: string;
  card_type: string;
  expiration_month: number;
  expiration_year: number;
  customer_id: number;
  current_vault: string;
  vault_token: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  billing_country: string;
  customer_vault_token?: any;
  billing_address_2: string;
  payment_type: string;
  disabled: boolean;
}

export interface ChargifySubscription {
  id: number;
  state: string;
  trial_started_at?: any;
  trial_ended_at?: any;
  activated_at: Date;
  created_at: Date;
  updated_at: Date;
  expires_at?: any;
  balance_in_cents: number;
  current_period_ends_at: Date;
  next_assessment_at: Date;
  canceled_at?: any;
  cancellation_message?: any;
  next_product_id?: any;
  next_product_handle?: any;
  cancel_at_end_of_period?: any;
  payment_collection_method: string;
  snap_day?: any;
  cancellation_method?: any;
  current_period_started_at: Date;
  previous_state: string;
  signup_payment_id: number;
  signup_revenue: string;
  delayed_cancel_at?: any;
  coupon_code?: any;
  total_revenue_in_cents: number;
  product_price_in_cents: number;
  product_version_number: number;
  payment_type: string;
  referral_code?: any;
  coupon_use_count?: any;
  coupon_uses_allowed?: any;
  reason_code?: any;
  automatically_resume_at?: any;
  coupon_codes: any[];
  offer_id?: any;
  payer_id?: any;
  current_billing_amount_in_cents: number;
  receives_invoice_emails?: any;
  product_price_point_id: number;
  next_product_price_point_id?: any;
  credit_balance_in_cents: number;
  prepayment_balance_in_cents: number;
  net_terms?: any;
  stored_credential_transaction_id?: any;
  locale?: any;
  reference?: any;
  customer: Customer;
  product: Product;
  credit_card: CreditCard;
}

export interface CurrentSubscription {
  success: boolean;
  data: Subscription;
}

export interface ChargifyPaymentMethod {
  readonly id?: string;
  first_name?: string;
  last_name?: string;
  customer_id?: number;
  current_vault?: string;
  vault_token?: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_country?: string;
  customer_vault_token?: string;
  billing_address_?: string;
  bank_name?: string;
  masked_bank_routing_number?: string;
  masked_bank_account_number?: string;
  bank_account_type?: string;
  bank_account_holder_type?: string;
  payment_type?: string;
}
export interface ChargifyPaymentMethodResponse {
  payment_method: ChargifyPaymentMethod;
}

export interface Coupon {
  readonly id?: string;
  readonly created_at?: Date;
  readonly updated_at?: Date;
  name: string;
  code: string;
  description: string;
  amount_in_cents: number;
  product_family_id: number;
  start_date: string;
  end_date: string | null;
  percentage: number;
  recurring: boolean; // If true, the discount will be applied at signup and every renewal (for the number of renewals given by duration_period_count). Otherwise, discount will be applied only at signup.
  duration_period_count: number | null;
  duration_interval: number | null;
  duration_interval_unit: number | null;
  allow_negative_balance: boolean; // If true, credits will carry forward to next billing. Otherwise discount may not exceed total of the charges.
  archived_at: null | string; // Timestamp indicating when this coupon was archived
  conversion_limit: null | number;
  stackable: boolean; // If true coupon can be combined with other stackable coupons to produce a larger discount. If false coupon cannot be combined with other coupons.
  compounding_strategy: 'compound' | 'full-price';
  coupon_restrictions: object[];
}
export interface CouponResponse {
  coupon: Coupon;
}

export interface ValidateCouponResponse {
  valid: boolean;
  promo_code: Coupon;
}

export interface UserBase {
  first_name: string;
  last_name: string;
  status: string;
  email: string;
  home_phone: string;
  mobile_phone: string;
  work_phone: string;
  state: string;
  id: number;
  has_payment_info: boolean;
  is_expired: boolean;
  links: any[];
}

export interface UsersResponse {
  success: boolean;
  data: UserBase[];
  meta: Meta;
}
export interface UsersResponseBillingAPI {
  users: User[];
  meta: { count: number };
}
export interface User {
  id: number;
  unread_notifications_count: number;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  work_phone: string;
  home_phone: string;
  mobile_phone: string;
  ssn: string;
  password: string;
  enrollment_hash?: any;
  enrolled_by_admin_id?: any;
  payment_day: number;
  payment_amount: number;
  promo_code: string;
  chargify_subscription_id: string;
  chargify_customer_id: string;
  chargify_payment_profile_id: string;
  chargify_subscription_status: ChargifySubscription['state'];
  chargify_price_point_handle: string;
  authorize_amount?: any;
  ach_bank_name?: any;
  ach_type?: any;
  ach_routing?: any;
  ach_account_number?: any;
  cc_last_4: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: any;
  is_mx_registered: number;
  locked: boolean;
  tos_accepted: number;
  tos_accepted_ip: string;
  loan_servicer_active: boolean;
  authnet_customer_profile_id: string;
  authnet_customer_payment_profile_id: string;
  current_subscription_id: number;
  reset_token?: any;
  profile_photo: string;
  csid?: any;
  status: string;
  status_override?: any;
  lead_source?: any;
  reactivation_attempt: number;
  suspended: boolean;
  has_payment_info: boolean;
  is_expired: boolean;
  links: any[];
  payments: {
    data: Payments[];
  };
  notifications: Notifications;
  current_subscription: CurrentSubscription;
  accountMessages: AccountMessages;
  [key: string]: any;
}

export interface Track {
  id: number;
  user_id: number;
  event_type: string;
  user_agent: string;
  ip_address: string;
  last_occurred_at: Date;
  occurrence_count: number;
  created_at: Date;
  updated_at: Date;
  links: any[];
  [key: string]: any;
}
export interface TracksResponse {
  success: boolean;
  data: Track[];
  meta: Meta;
}

export interface UserSignInResponse {
  success: boolean;
  data: User;
  meta: Meta;
}

export interface UserResponse {
  user: User;
}

export interface Payments {
  id: number;
  user_id: number;
  result: string;
  amount_charged: number;
  created_at: Date;
  user: User;
  links: {
    'admin.payments.show': PermissibleLink;
  };
}

export interface NextPageLink {
  next: string;
}

export interface Pagination {
  total: number;
  count: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  links: NextPageLink;
}

export interface Meta {
  links: PermissibleLinks;
  token: string;
  pagination?: Pagination;
}

export interface AdminDashboardResponse {
  users: number;
  leads: number;
  admins: number;
}
export interface PaymentsResponse {
  success: boolean;
  data: Payment[];
  meta: Meta;
}

export interface TokenMeta {
  token: string;
}

export interface TokenResponse {
  success: boolean;
  meta: TokenMeta;
}

export interface SubscriptionResponse {
  success: boolean;
  data: Subscription;
  meta: Meta;
}

export interface PromoCodesResponse {
  promo_codes: Coupon[];
}

export interface ChargifySubscriptionResponse {
  subscription: ChargifySubscription;
}

export interface ChargifySubscriptionWithUserResponse {
  subscription: ChargifySubscription;
  user: User;
}

export type UpdateCreditCardValues = {
  number: string;
  cvv: string;
  zip: string;
  exp_month: string;
  exp_year: string;
};

export interface AdminAuthorization {
  id: number;
  admin_id: number;
  grant_type: string;
  identifier: string;
  created_at: Date;
  updated_at: Date;
}

export interface AuthorizationsMeta {
  count: number;
}

export interface AuthorizationsResponse {
  admin_authorizations: AdminAuthorization[];
  meta: AuthorizationsMeta;
}

export interface AdminAuthorization {
  id: number;
  grant_type: string;
  identifier: string;
  admin_id: number;
  updated_at: Date;
  created_at: Date;
}

export interface SalesforceUserFull {
  attributes: SalesforceUserAttributes;
  Id: string;
  Username: string;
  LastName: string;
  FirstName: string;
  Name: string;
  CompanyName?: any;
  Division?: any;
  Department?: any;
  Title?: any;
  Street?: any;
  City?: any;
  State?: any;
  PostalCode?: any;
  Country?: any;
  Latitude?: any;
  Longitude?: any;
  GeocodeAccuracy?: any;
  Address?: any;
  Email: string;
  EmailPreferencesAutoBcc: boolean;
  EmailPreferencesAutoBccStayInTouch: boolean;
  EmailPreferencesStayInTouchReminder: boolean;
  SenderEmail?: any;
  SenderName?: any;
  Signature?: any;
  StayInTouchSubject?: any;
  StayInTouchSignature?: any;
  StayInTouchNote?: any;
  Phone?: any;
  Fax?: any;
  MobilePhone?: any;
  Alias: string;
  CommunityNickname: string;
  BadgeText: string;
  IsActive: boolean;
  TimeZoneSidKey: string;
  UserRoleId?: any;
  LocaleSidKey: string;
  ReceivesInfoEmails: boolean;
  ReceivesAdminInfoEmails: boolean;
  EmailEncodingKey: string;
  ProfileId: string;
  UserType: string;
  LanguageLocaleKey: string;
  EmployeeNumber?: any;
  DelegatedApproverId?: any;
  ManagerId?: any;
  LastLoginDate?: any;
  LastPasswordChangeDate?: any;
  CreatedDate: Date;
  CreatedById: string;
  LastModifiedDate: Date;
  LastModifiedById: string;
  SystemModstamp: Date;
  OfflineTrialExpirationDate?: any;
  OfflinePdaTrialExpirationDate?: any;
  UserPermissionsMarketingUser: boolean;
  UserPermissionsOfflineUser: boolean;
  UserPermissionsAvantgoUser: boolean;
  UserPermissionsCallCenterAutoLogin: boolean;
  UserPermissionsMobileUser: boolean;
  UserPermissionsSFContentUser: boolean;
  UserPermissionsInteractionUser: boolean;
  UserPermissionsSupportUser: boolean;
  UserPermissionsChatterAnswersUser: boolean;
  ForecastEnabled: boolean;
  UserPreferencesActivityRemindersPopup: boolean;
  UserPreferencesEventRemindersCheckboxDefault: boolean;
  UserPreferencesTaskRemindersCheckboxDefault: boolean;
  UserPreferencesReminderSoundOff: boolean;
  UserPreferencesDisableAllFeedsEmail: boolean;
  UserPreferencesDisableFollowersEmail: boolean;
  UserPreferencesDisableProfilePostEmail: boolean;
  UserPreferencesDisableChangeCommentEmail: boolean;
  UserPreferencesDisableLaterCommentEmail: boolean;
  UserPreferencesDisProfPostCommentEmail: boolean;
  UserPreferencesApexPagesDeveloperMode: boolean;
  UserPreferencesHideCSNGetChatterMobileTask: boolean;
  UserPreferencesDisableMentionsPostEmail: boolean;
  UserPreferencesDisMentionsCommentEmail: boolean;
  UserPreferencesHideCSNDesktopTask: boolean;
  UserPreferencesHideChatterOnboardingSplash: boolean;
  UserPreferencesHideSecondChatterOnboardingSplash: boolean;
  UserPreferencesDisCommentAfterLikeEmail: boolean;
  UserPreferencesDisableLikeEmail: boolean;
  UserPreferencesSortFeedByComment: boolean;
  UserPreferencesDisableMessageEmail: boolean;
  UserPreferencesDisableBookmarkEmail: boolean;
  UserPreferencesDisableSharePostEmail: boolean;
  UserPreferencesEnableAutoSubForFeeds: boolean;
  UserPreferencesDisableFileShareNotificationsForApi: boolean;
  UserPreferencesShowTitleToExternalUsers: boolean;
  UserPreferencesShowManagerToExternalUsers: boolean;
  UserPreferencesShowEmailToExternalUsers: boolean;
  UserPreferencesShowWorkPhoneToExternalUsers: boolean;
  UserPreferencesShowMobilePhoneToExternalUsers: boolean;
  UserPreferencesShowFaxToExternalUsers: boolean;
  UserPreferencesShowStreetAddressToExternalUsers: boolean;
  UserPreferencesShowCityToExternalUsers: boolean;
  UserPreferencesShowStateToExternalUsers: boolean;
  UserPreferencesShowPostalCodeToExternalUsers: boolean;
  UserPreferencesShowCountryToExternalUsers: boolean;
  UserPreferencesShowProfilePicToGuestUsers: boolean;
  UserPreferencesShowTitleToGuestUsers: boolean;
  UserPreferencesShowCityToGuestUsers: boolean;
  UserPreferencesShowStateToGuestUsers: boolean;
  UserPreferencesShowPostalCodeToGuestUsers: boolean;
  UserPreferencesShowCountryToGuestUsers: boolean;
  UserPreferencesHideS1BrowserUI: boolean;
  UserPreferencesDisableEndorsementEmail: boolean;
  UserPreferencesPathAssistantCollapsed: boolean;
  UserPreferencesCacheDiagnostics: boolean;
  UserPreferencesShowEmailToGuestUsers: boolean;
  UserPreferencesShowManagerToGuestUsers: boolean;
  UserPreferencesShowWorkPhoneToGuestUsers: boolean;
  UserPreferencesShowMobilePhoneToGuestUsers: boolean;
  UserPreferencesShowFaxToGuestUsers: boolean;
  UserPreferencesShowStreetAddressToGuestUsers: boolean;
  UserPreferencesLightningExperiencePreferred: boolean;
  UserPreferencesPreviewLightning: boolean;
  UserPreferencesHideEndUserOnboardingAssistantModal: boolean;
  UserPreferencesHideLightningMigrationModal: boolean;
  UserPreferencesHideSfxWelcomeMat: boolean;
  UserPreferencesHideBiggerPhotoCallout: boolean;
  UserPreferencesGlobalNavBarWTShown: boolean;
  UserPreferencesGlobalNavGridMenuWTShown: boolean;
  UserPreferencesCreateLEXAppsWTShown: boolean;
  UserPreferencesFavoritesWTShown: boolean;
  UserPreferencesRecordHomeSectionCollapseWTShown: boolean;
  UserPreferencesRecordHomeReservedWTShown: boolean;
  UserPreferencesFavoritesShowTopFavorites: boolean;
  UserPreferencesExcludeMailAppAttachments: boolean;
  UserPreferencesSuppressTaskSFXReminders: boolean;
  UserPreferencesSuppressEventSFXReminders: boolean;
  UserPreferencesPreviewCustomTheme: boolean;
  UserPreferencesHasCelebrationBadge: boolean;
  ContactId?: any;
  AccountId?: any;
  CallCenterId: string;
  Extension?: any;
  FederationIdentifier?: any;
  AboutMe?: any;
  FullPhotoUrl: string;
  SmallPhotoUrl: string;
  IsExtIndicatorVisible: boolean;
  OutOfOfficeMessage: string;
  MediumPhotoUrl: string;
  DigestFrequency: string;
  DefaultGroupNotificationFrequency: string;
  LastViewedDate?: any;
  LastReferencedDate?: any;
  BannerPhotoUrl: string;
  SmallBannerPhotoUrl: string;
  MediumBannerPhotoUrl: string;
  IsProfilePhotoActive: boolean;
  IndividualId?: any;
  CreditSoft_UserID__c?: any;
  dsfs__DSProSFMembershipStatus__c?: any;
  dsfs__DSProSFPassword__c?: any;
  dsfs__DSProSFUsername__c?: any;
  talkdesk__IsLicensed__c: boolean;
  Group__c?: any;
  Phones_Start_Date__c?: any;
  Fronter_Transfers__c: boolean;
  pi__Can_View_Not_Assigned_Prospects__c: boolean;
  pi__Pardot_Api_Key__c?: any;
  pi__Pardot_Api_Version__c?: any;
  pi__Pardot_User_Id__c?: any;
  pi__Pardot_User_Key__c?: any;
  pi__Pardot_User_Role__c?: any;
  Get_Lead__c?: any;
  Contact_Attempt__c?: any;
  Lead_Jumping__c: boolean;
  Assignment_Group_Active__c: boolean;
}

export interface AddAuthorizationResponse {
  admin_authorization: AdminAuthorization;
  identifier: SalesforceUserFull;
}

export interface ProductPricePoint {
  handle: string;
  price: number;
}

export interface ProductPricePointResponse {
  product_price_points: ProductPricePoint[];
}

export interface EnrollmentData {
  id: number;
  status: string;
  first_name: string;
  last_name: string;
  email: string;
  phone?: any;
  address?: any;
  city?: any;
  state?: any;
  zip?: any;
  has_payment_info: boolean;
  cc_last_4?: any;
  tos_accepted: boolean;
}

export interface EnrollmentResponse {
  success: boolean;
  data: EnrollmentData;
  meta: Meta;
}

export interface ChargifyPayment {
  id: number;
  user_id: number;
  result: string;
  amount_charged: number;
  full_amount: number;
  payment_type: string;
  previous_user_status: string;
  chargify_transaction_id: string;
  created_at: Date;
  updated_at: Date;
  error_reviewed: number;
  confirmation_code?: any;
  payment_information: string;
  subscription_id?: any;
  authnet_transid?: any;
  authnet_status?: any;
  authnet_reason_code?: any;
  authnet_authcode?: any;
  authnet_account_number?: any;
  authnet_paynum?: any;
  authnet_submitted_at: Date;
  authnet_settled_at?: any;
  batch_id?: any;
}

interface ChargifyPaymentsMeta {
  count: number;
}

export interface ChargifyPaymentsResponse {
  payments: Payment[];
  meta: ChargifyPaymentsMeta;
}
