import LogRocket from 'logrocket';
import { LOGROCKET_ENABLED, LOGROCKET_DOMAIN } from '../config';
import isStringTrue from './isStringTrue';

const LRSetup = require('logrocket/setup');

let stub: typeof LogRocket = {
  track: (eventName: string) => {
    console.log('LOGROCKET_TRACK:', eventName);
    return true;
  },
  identify: (uid: string, options: any) => {
    console.log('LOGROCKET_IDENTIFY', uid, options);
    return true;
  },
  log: (...props: any[]) => {
    console.log('LOGROCKET_LOG', props);
    return true;
  },
  info: (...props: any[]) => {
    console.log('LOGROCKET_INFO', props);
    return true;
  },
  warn: (...props: any[]) => {
    console.log('LOGROCKET_WARN', props);
    return true;
  },
  error: (...props: any[]) => {
    console.log('LOGROCKET_ERROR', props);
    return true;
  },
  debug: (...props: any[]) => {
    console.log('LOGROCKET_DEBUG', props);
    return true;
  },
  reduxMiddleware: (options: any) => {
    console.log('LOGROCKET_REDUXMIDDLEWARE', options);
    return true;
  },
  startNewSession: () => {
    console.log('LOGROCKET_STARTNEWSESSION');
    return true;
  },

  captureMessage: (message: string, options: any) => {
    console.log('LOGROCKET_CAPTUREMESSAGE', message, options);
    return true;
  },
  captureException: (exception: Error, options: any) => {
    console.log('LOGROCKET_CAPTUREEXCEPTION', exception, options);
    return true;
  },
} as any;

export default {
  initialized: false,
  logrocketClient: stub,
  async setup() {
    if (
      isStringTrue(LOGROCKET_ENABLED) &&
      LOGROCKET_DOMAIN &&
      !this.initialized
    ) {
      this.logrocketClient = LRSetup();
      await this.logrocketClient.init(LOGROCKET_DOMAIN);
    }
    this.initialized = true;
  },
  client() {
    if (!this.initialized) {
      this.setup();
    }
    return this.logrocketClient;
  },
};
