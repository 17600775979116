import {
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import WorthyyAPI, {
  UsersResponse,
  UserBase,
  User,
  UserSignInResponse,
  AccountMessage,
  Track,
  TracksResponse,
  UserDeleteResponse,
  UserResponse,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';
import { fetchTracksSuccess } from 'features/tracks/tracksSlice';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (id: string) => {
    return WorthyyAPI.admin.users.findById(id);
  }
);

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (search?: any) => {
    return WorthyyAPI.admin.users.search(search);
  }
);

export interface UsersState {
  item: User;
  accountMessages: AccountMessage[];
  tracks: Track[];
  items: UserBase[];
  itemsById: { [key: string]: UserBase | User };
  pagination: { total: number };
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

interface ById {
  [key: string]: any;
}
let initialState: UsersState = {
  item: {} as User,
  accountMessages: [],
  tracks: [],
  items: [],
  itemsById: {},
  pagination: { total: 0 },
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: UsersState) {
  state.isLoading = true;
}

function loadingFailed(state: UsersState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    syncWithSFStart: startLoading,
    updateUserStart: startLoading,
    setUserPasswordStart: startLoading,
    deleteUserStart: startLoading,
    resendEnrollmentEmailStart: startLoading,

    fetchUsersSuccess(state, { payload }: PayloadAction<UsersResponse>) {
      const {
        data,
        meta: { pagination },
      } = payload;
      state.items = data;
      state.itemsById = data.reduce((acc: ById, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      state.pagination = pagination || { total: data.length };
      state.isFetched = true;
      state.isLoading = false;
    },

    updateUserSuccess(state, { payload }: PayloadAction<UserResponse>) {
      const { user } = payload;
      state.item = user;
      state.itemsById[user.id] = user;
      state.isLoading = false;
    },

    setUserPasswordSuccess(
      state,
      { payload }: PayloadAction<UserSignInResponse>
    ) {
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
    },

    syncWithSFSuccess(state, { payload }: PayloadAction<UserSignInResponse>) {
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
    },

    fetchTracksSuccess(state, { payload }: PayloadAction<TracksResponse>) {
      const { data } = payload;
      state.isLoading = false;
      state.tracks = data.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    },

    resendEnrollmentEmailSuccess(state, action) {
      state.isLoading = false;
    },

    deleteUserSuccess(state, action: PayloadAction<UserDeleteResponse>) {
      state.isLoading = false;
      state.errorMessage = '';
    },

    syncWithSFFailure: loadingFailed,
    updateUserFailure: loadingFailed,
    setUserPasswordFailure: loadingFailed,
    deleteUserFailure: loadingFailed,
    resendEnrollmentEmailFailure: loadingFailed,
  },
  extraReducers: builder => {
    builder.addCase(fetchUser.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { data } = payload;
      state.item = data;
      state.itemsById[data.id] = data;
      state.isLoading = false;
      state.accountMessages = data.accountMessages?.data?.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.toString();
    });

    builder.addCase(fetchUsers.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      const {
        data,
        meta: { pagination },
      } = payload;
      state.items = data;
      state.itemsById = data.reduce((acc: ById, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      state.pagination = pagination || { total: data.length };
      state.isFetched = true;
      state.isLoading = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.toString();
    });

    builder.addCase(fetchTracksSuccess, (state, { payload }) => {
      const { data } = payload;
      state.tracks = data;
    });
  },
});

export const {
  syncWithSFStart,
  syncWithSFSuccess,
  syncWithSFFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  setUserPasswordStart,
  setUserPasswordSuccess,
  setUserPasswordFailure,
  resendEnrollmentEmailStart,
  resendEnrollmentEmailSuccess,
  resendEnrollmentEmailFailure,
} = usersSlice.actions;

export default usersSlice.reducer;

export const resendEnrollmentEmail = (
  userId: number | string
): AppThunk => async dispatch => {
  try {
    dispatch(resendEnrollmentEmailStart());
    const data = await WorthyyAPI.admin.enrollments.send(userId);
    dispatch(resendEnrollmentEmailSuccess(data));
  } catch (err) {
    dispatch(resendEnrollmentEmailFailure(err.toString()));
  }
};

export const updateUser = (
  id: number | string,
  fields: Partial<User>
): AppThunk => async dispatch => {
  try {
    dispatch(updateUserStart());
    const data = await WorthyyAPI.admin.users.update(id, fields);
    dispatch(updateUserSuccess(data));
  } catch (err) {
    dispatch(updateUserFailure(err.toString()));
  }
};

export const deleteUser = (id: number | string): AppThunk => async dispatch => {
  try {
    dispatch(deleteUserStart());
    const data = await WorthyyAPI.admin.users.delete(id);
    dispatch(deleteUserSuccess(data));
  } catch (err) {
    dispatch(deleteUserFailure(err.toString()));
  }
};

export const setUserPassword = (
  id: number | string,
  newPassword: string
): AppThunk => async dispatch => {
  try {
    dispatch(setUserPasswordStart());
    const data = await WorthyyAPI.admin.users.setPassword(id, newPassword);
    dispatch(setUserPasswordSuccess(data));
  } catch (err) {
    dispatch(setUserPasswordFailure(err.toString()));
  }
};

export const syncUserWithSF = (
  id: number | string
): AppThunk => async dispatch => {
  try {
    dispatch(syncWithSFStart());
    const data = await WorthyyAPI.admin.users.syncWithSF(id);
    dispatch(syncWithSFSuccess(data));
  } catch (err) {
    dispatch(syncWithSFFailure(err.toString()));
  }
};

// selectors

export const selectUser = createSelector(
  (state: RootState) => state.users.item,
  user => user
);
export const selectUsers = createSelector(
  (state: RootState) => state.users.items,
  users => users
);

export const selectUsersCount = createSelector(
  (state: RootState) => state.users.pagination.total,
  count => count
);

export const selectUsersLoading = createSelector(
  (state: RootState) => state.users.isLoading,
  bool => bool
);

export const selectUserAccountMessages = createSelector(
  (state: RootState) => state.users.accountMessages,
  messages => messages
);
