import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import WorthyyAPI, { Payment } from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export const fetchPaymentsForUser = createAsyncThunk(
  'payments/fetchPaymentsForUser',
  async (id: number) => {
    const data = await WorthyyAPI.admin.payments.findByUserId(id);
    return data;
  }
);

export const fetchPaymentsSelf = createAsyncThunk(
  'payments/fetchPaymentsSelf',
  async () => {
    const data = await WorthyyAPI.user.payments.list();
    return data;
  }
);

export const fetchPayments = createAsyncThunk('payments/fetchAll', async () => {
  const result = await WorthyyAPI.admin.payments.search();
  return result;
});

const adapter = createEntityAdapter<Payment>({
  sortComparer: (a, b) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
});

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: adapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPaymentsSelf.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchPaymentsSelf.fulfilled, (state, action) => {
      state.loading = false;
      adapter.setAll(state, action.payload.payments);
    });
    builder.addCase(fetchPaymentsSelf.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchPaymentsForUser.pending, (state, action) => {
      adapter.removeAll(state);
      state.loading = true;
    });
    builder.addCase(fetchPaymentsForUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.payments) {
        adapter.setAll(state, action.payload.payments);
      }
    });
    builder.addCase(fetchPaymentsForUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchPayments.pending, (state, action) => {
      adapter.removeAll(state);
      state.loading = true;
    });
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      state.loading = false;
      adapter.setAll(state, action.payload.data);
    });
    builder.addCase(fetchPayments.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default paymentsSlice.reducer;

// selectors

export const {
  selectAll: selectAllPayments,
  selectTotal: selectTotalPayments,
} = adapter.getSelectors((state: RootState) => state.payments);

export const selectPaymentsLoading = createSelector(
  (state: RootState) => state.payments.loading,
  loading => loading
);
