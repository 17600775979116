import axios from 'axios';
import startsWith from 'lodash/startsWith';

import { API_URI } from '../config';

import { signOut } from 'features/auth/authSlice';
import { Store } from '@reduxjs/toolkit';

export default {
  setup: (store: Store) => {
    axios.interceptors.response.use(
      (response: any) => {
        const token = response.data?.meta?.token;
        let storageType = localStorage;
        if (token) {
          if (sessionStorage.getItem('access_token')) {
            storageType = sessionStorage;
          } 
          storageType.setItem('access_token', token);
        }
        return response;
      },
      error => {
        const isWorthyyAPI = startsWith(error.response?.config?.url, API_URI);
        if (
          error.response.status === 401 &&
          error.response.config.withCredentials &&
          isWorthyyAPI
        ) {
          store.dispatch(signOut());
        }
        return Promise.reject(error);
      }
    );
  },
};
