import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ButtonLoadingProps extends ButtonProps {
  loading?: boolean;
}
const ButtonLoading: React.FC<ButtonLoadingProps> = props => {
  const { children, loading = false, ...rest } = props;
  return (
    <Button {...rest} {...(loading && { disabled: true })}>
      {loading && <CircularProgress size={20} style={{ marginRight: 5 }} />}
      {children}
    </Button>
  );
};

export default ButtonLoading;
