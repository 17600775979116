import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk, AsyncThunk } from 'app/store';
import WorthyyAPI, {
  Subscription,
  SubscriptionResponse,
  UpdateCreditCardValues,
  User,
  ChargifySubscription,
  ChargifySubscriptionResponse,
  CreateSubscriptionValues,
  ChargifyPaymentMethodResponse,
  ChargifySubscriptionWithUserResponse,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';
import { fetchUser } from '../users/usersSlice';
import { AxiosError } from 'axios';

export interface SubscriptionState {
  item: Subscription;
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

interface ById {
  [key: string]: any;
}
let initialState: SubscriptionState = {
  item: {} as Subscription,
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: SubscriptionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: SubscriptionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const paymentsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchSubscriptionStart: startLoading,
    cancelSubscriptionStart: startLoading,
    reactivateSubscriptionStart: startLoading,
    retryPaymentStart: startLoading,
    setPaymentDateStart: startLoading,
    setPromoCodeStart: startLoading,
    updatePaymentMethodStart: startLoading,
    createSubscriptionStart: startLoading,

    fetchSubscriptionSuccess(
      state,
      { payload }: PayloadAction<ChargifySubscriptionWithUserResponse>
    ) {
      const { subscription } = payload;
      state.item.chargify = subscription;
      state.isFetched = true;
      state.isLoading = false;
    },
    cancelSubscriptionSuccess(
      state,
      { payload }: PayloadAction<SubscriptionResponse>
    ) {
      const { data } = payload;
      state.item = data;
      state.isFetched = true;
      state.isLoading = false;
    },
    createSubscriptionSuccess(
      state,
      { payload }: PayloadAction<ChargifySubscriptionResponse>
    ) {
      // this needs help - this doesn't return the sub as expected
      // state.item.chargify = payload.subscription;
      state.isFetched = true;
      state.isLoading = false;
    },
    reactivateSubscriptionSuccess(
      state,
      { payload }: PayloadAction<ChargifySubscriptionResponse>
    ) {
      const { subscription } = payload;
      // TODO: this is whack, we need to go all in on billing, or all in on legacy apis
      state.item.status = subscription.state;
      state.item.chargify = subscription;
      state.isFetched = true;
      state.isLoading = false;
    },
    retryPaymentSuccess(
      state,
      { payload }: PayloadAction<ChargifySubscriptionResponse>
    ) {
      const { subscription } = payload;
      // TODO: this is whack, we need to go all in on billing, or all in on legacy apis
      state.item.status = subscription.state;
      state.item.chargify = subscription;
      state.isFetched = true;
      state.isLoading = false;
    },
    setPaymentDateSuccess(
      state,
      { payload }: PayloadAction<ChargifySubscriptionResponse>
    ) {
      const { subscription } = payload;
      // TODO: this is whack, we need to go all in on billing, or all in on legacy apis
      state.item.status = subscription.state;
      state.item.chargify = subscription;
      state.isFetched = true;
      state.isLoading = false;
    },

    setPromoCodeSuccess(
      state,
      {
        payload,
      }: PayloadAction<{ user: User; subscription: ChargifySubscription }>
    ) {
      state.item.chargify = payload.subscription;
      state.isFetched = true;
      state.isLoading = false;
    },

    updatePaymentMethodSuccess(
      state,
      { payload }: PayloadAction<ChargifyPaymentMethodResponse>
    ) {},

    fetchSubscriptionFailure: loadingFailed,
    cancelSubscriptionFailure: loadingFailed,
    reactivateSubscriptionFailure: loadingFailed,
    retryPaymentFailure: loadingFailed,
    setPaymentDateFailure: loadingFailed,
    setPromoCodeFailure: loadingFailed,
    updatePaymentMethodFailure: loadingFailed,
    createSubscriptionFailure: loadingFailed,
  },
  extraReducers: builder => {
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.item = data.current_subscription?.data;
    });
  },
});

export const {
  fetchSubscriptionStart,
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
  cancelSubscriptionStart,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  reactivateSubscriptionStart,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFailure,
  retryPaymentStart,
  retryPaymentSuccess,
  retryPaymentFailure,
  setPaymentDateStart,
  setPaymentDateSuccess,
  setPaymentDateFailure,
  setPromoCodeStart,
  setPromoCodeSuccess,
  setPromoCodeFailure,
  updatePaymentMethodStart,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailure,
  createSubscriptionStart,
  createSubscriptionSuccess,
  createSubscriptionFailure,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;

export const fetchSubscription = (
  userId: number
): AppThunk => async dispatch => {
  try {
    dispatch(fetchSubscriptionStart());
    const data = await WorthyyAPI.admin.subscriptions.show(userId);
    dispatch(fetchSubscriptionSuccess(data));
  } catch (err) {
    dispatch(fetchSubscriptionFailure(err.toString()));
  }
};

export const fetchSubscriptionSelf = (): AsyncThunk<ChargifySubscriptionWithUserResponse> => async dispatch => {
  try {
    dispatch(fetchSubscriptionStart());
    const data = await WorthyyAPI.user.subscription.show();
    return dispatch(fetchSubscriptionSuccess(data));
  } catch (err) {
    const error = err.response.data;
    dispatch(fetchSubscriptionFailure(error.msg || err.toString()));
    throw error;
  }
};

export const cancelSubscription = (
  userId: number
): AppThunk => async dispatch => {
  try {
    dispatch(cancelSubscriptionStart());
    const data = await WorthyyAPI.admin.subscriptions.cancel(userId);
    dispatch(cancelSubscriptionSuccess(data));
  } catch (err) {
    dispatch(cancelSubscriptionFailure(err.toString()));
    throw err;
  }
};

export const createSubscription = (
  userId: number,
  values: CreateSubscriptionValues
): AppThunk => async dispatch => {
  try {
    dispatch(createSubscriptionStart());
    const withoutNull = Object.keys(values).reduce((acc, key) => {
      if (![null, ''].includes(values[key])) {
        acc[key] = values[key];
      }
      return acc;
    }, {} as any);
    const data = await WorthyyAPI.admin.subscriptions.create(
      userId,
      withoutNull
    );
    dispatch(createSubscriptionSuccess(data));
  } catch (err) {
    dispatch(createSubscriptionFailure(err.toString()));
    throw err;
  }
};

export const createSubscriptionSelf = (
  values: CreateSubscriptionValues
): AppThunk => async dispatch => {
  try {
    dispatch(createSubscriptionStart());
    const withoutNull = Object.keys(values).reduce((acc, key) => {
      if (![null, ''].includes(values[key])) {
        acc[key] = values[key];
      }
      return acc;
    }, {} as any);
    const data = await WorthyyAPI.user.subscription.create(withoutNull);
    dispatch(createSubscriptionSuccess(data));
  } catch (err) {
    dispatch(createSubscriptionFailure(err.toString()));
    throw err;
  }
};

export const reactivateSubscription = (
  userId: number,
  date: string
): AppThunk => async dispatch => {
  try {
    dispatch(reactivateSubscriptionStart());
    const data = await WorthyyAPI.admin.subscriptions.reactivate(userId, date);
    dispatch(reactivateSubscriptionSuccess(data));
  } catch (err) {
    let error: AxiosError = err;
    dispatch(
      reactivateSubscriptionFailure(error.response?.data.msg ?? err.toString())
    );
    throw error;
  }
};

export const retryPayment = (userId: number): AppThunk => async dispatch => {
  try {
    dispatch(retryPaymentStart());
    const data = await WorthyyAPI.admin.subscriptions.retryPayment(userId);
    dispatch(retryPaymentSuccess(data));
  } catch (err) {
    let error: AxiosError = err;
    console.log('error', error);
    dispatch(retryPaymentFailure(error.response?.data.msg ?? err.toString()));
    throw error;
  }
};

export const retryPaymentSelf = (): AppThunk => async dispatch => {
  try {
    dispatch(retryPaymentStart());
    const data = await WorthyyAPI.user.subscription.retryPayment();
    dispatch(retryPaymentSuccess(data));
  } catch (err) {
    let error: AxiosError = err;
    console.log('error', error);
    dispatch(retryPaymentFailure(error.response?.data.msg ?? err.toString()));
    throw error;
  }
};

export const setNextPaymentDate = (
  userId: number,
  date: Date
): AppThunk => async dispatch => {
  let newDate = date;
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  try {
    dispatch(setPaymentDateStart());
    const data = await WorthyyAPI.admin.subscriptions.setPaymentDate(
      userId,
      newDate
    );
    return dispatch(setPaymentDateSuccess(data));
  } catch (err) {
    dispatch(setPaymentDateFailure(err.response?.data.msg ?? err.toString()));
    throw err;
  }
};

export const setPromoCode = (
  userId: number,
  code: string
): AppThunk => async dispatch => {
  try {
    dispatch(setPromoCodeStart());
    const data = await WorthyyAPI.admin.subscriptions.setPromoCode(
      userId,
      code
    );
    return dispatch(setPromoCodeSuccess(data));
  } catch (err) {
    dispatch(setPromoCodeFailure(err.toString()));
    throw err;
  }
};

export const removePromoCode = (
  userId: number
): AppThunk<Promise<
  PayloadAction<{ user: User; subscription: ChargifySubscription }>
>> => async dispatch => {
  try {
    dispatch(setPromoCodeStart());
    const data = await WorthyyAPI.admin.subscriptions.removePromoCode(userId);
    return dispatch(setPromoCodeSuccess(data));
  } catch (err) {
    dispatch(setPromoCodeFailure(err.toString()));
    throw err;
  }
};

export const updatePaymentMethod = (
  userId: number,
  cardDetails: UpdateCreditCardValues
): AppThunk => async dispatch => {
  try {
    dispatch(updatePaymentMethodStart());
    const data = await WorthyyAPI.admin.subscriptions.updatePaymentMethod(
      userId,
      cardDetails
    );
    return dispatch(updatePaymentMethodSuccess(data));
  } catch (err) {
    const error = err.response.data;
    dispatch(updatePaymentMethodFailure(error.msg || err.toString()));
    throw error;
  }
};

export const updatePaymentMethodSelf = (
  cardDetails: UpdateCreditCardValues
): AppThunk => async dispatch => {
  try {
    dispatch(updatePaymentMethodStart());
    const data = await WorthyyAPI.user.subscription.updatePaymentMethod(
      cardDetails
    );
    return dispatch(updatePaymentMethodSuccess(data));
  } catch (err) {
    const error = err.response.data;
    dispatch(updatePaymentMethodFailure(error.msg || err.toString()));
    throw error;
  }
};

// selectors

export const selectSubscription = createSelector(
  (state: RootState) => state.subscription.item,
  subscription => subscription
);

export const selectSubscriptionLoading = createSelector(
  (state: RootState) => state.subscription.isLoading,
  bool => bool
);

export const selectSubscriptionFetched = createSelector(
  (state: RootState) => state.subscription.isFetched,
  bool => bool
);
