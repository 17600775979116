import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import {
  getUserFromEnrollmentToken,
  // consumeEnrollmentToken,
} from './authSlice';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import worthyyLogo from '../../images/worthyy-logo.svg';
import Copyright from 'components/Copyright';
import { useParams } from 'react-router';
import Wizard from 'utils/FormWizard';
import { Field } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';
import { CircularProgress, FormControlLabel, Divider } from '@material-ui/core';
import { AppDispatch } from 'app/store';
import { EnrollmentData } from 'api/worthyyAPI';
import { CreditCardFields } from 'features/users/CreditCardForm';
import { updatePasswordSchema } from './auth.schemas';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  img: {
    maxWidth: '45%',
    marginBottom: 30,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EnrollmentPage: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const { token } = useParams<{ token: string }>();
  const [tempUser, setTempUser] = useState<EnrollmentData>(
    {} as EnrollmentData
  );

  const { isLoading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const getUser = async () => {
      const result = await dispatch(getUserFromEnrollmentToken(token));
      setTempUser(result.payload.data);
    };
    if (token) {
      getUser();
    }
  }, [token, dispatch]);

  if (isLoading) {
    return (
      <div
        style={{
          fontSize: 100,
          margin: '0 auto',
          paddingTop: 100,
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={worthyyLogo} className={classes.img} alt="Worthyy logo" />
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Welcome to Worthyy, {tempUser.first_name}!
        </Typography>
        <Divider style={{ marginBottom: 30 }} />
        <Wizard
          initialValues={{
            password: '',
            password_confirmation: '',
            tos_accepted: false,
          }}
          onSubmit={(values, actions) => {
            console.log('values, actions', values, actions);
          }}
          disablePrevious={true}
          submitText="Activate my account"
        >
          <Wizard.Page validationSchema={updatePasswordSchema}>
            <Typography component="h3" variant="h6">
              Set Your Password
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              style={{ marginBottom: 10 }}
            >
              To get started, let's create your password. It's important to
              create a strong password with at least 6 characters.
            </Typography>
            <div>
              <Field
                required
                name="password"
                label="Password"
                component={TextField}
                margin="normal"
                autoComplete="new-password"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
              />
              <Field
                required
                name="password_confirmation"
                label="Confirm Password"
                component={TextField}
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
              />
              {!tempUser.tos_accepted && (
                <FormControlLabel
                  control={
                    <Field
                      required
                      name="tos_accepted"
                      label="Terms of Service"
                      component={Checkbox}
                      margin="normal"
                    />
                  }
                  label={
                    <>
                      I agree to the{' '}
                      <a
                        href="https://worthyy.com/terms/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms of Service
                      </a>
                    </>
                  }
                />
              )}
            </div>
          </Wizard.Page>
          <Wizard.Page>
            <div>
              {tempUser.has_payment_info ? (
                <>
                  <Typography component="h3" variant="h6">
                    Confirm your billing information
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    style={{ marginBottom: 10 }}
                  >
                    Last step - make sure this matches your current billing
                    information.
                  </Typography>

                  <div>
                    The last 4 you have on file is: {tempUser.cc_last_4}
                  </div>
                </>
              ) : (
                <>
                  <Typography component="h3" variant="h6">
                    Add your billing information
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    style={{ marginBottom: 10 }}
                  >
                    Last step - we need a card on file to activate your account.
                  </Typography>
                  <CreditCardFields />
                </>
              )}
            </div>
          </Wizard.Page>
        </Wizard>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default EnrollmentPage;
