export default (input: string | undefined): boolean => {
  if (!input) {
    return false;
  }

  switch (String(input).toLowerCase()) {
    case 'true':
    case '1':
    case 'y':
    case 'yes':
      return true;
    default:
      return false;
  }
};
