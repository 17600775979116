import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import authReducer from 'features/auth/authSlice';
import paymentsReducer from 'features/payments/paymentsSlice';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import usersReducer from 'features/users/usersSlice';
import adminsReducer from 'features/admins/adminsSlice';
import authorizationsReducer from 'features/authorizations/authorizationsSlice';
import salesforceUsersReducer from 'features/salesforce_users/salesforceUsersSlice';

import tracksReducer from 'features/tracks/tracksSlice';

import subscriptionReducer from 'features/subscription/subscriptionSlice';
import promoCodesReducer from 'features/promo_codes/promocodesSlice';
import pricePointsReducer from 'features/price_points/pricePointsSlice';

const createRootReducer = () => {
  const allReducers = combineReducers({
    auth: authReducer,
    payments: paymentsReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    admins: adminsReducer,
    authorizations: authorizationsReducer,
    salesforceUsers: salesforceUsersReducer,
    subscription: subscriptionReducer,
    promoCodes: promoCodesReducer,
    pricePoints: pricePointsReducer,
    tracks: tracksReducer,
  });

  const appReducer = (state: any, action: AnyAction) => {
    if (action.type === 'auth/signOut') {
      state = undefined;
    }

    return allReducers(state, action);
  };

  return appReducer;
};

const rootReducer = createRootReducer();
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
