import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from 'components/PrivateRoute';
import EnrollmentPage from 'features/auth/EnrollmentPage';
import { LogRocket } from 'utils';
import {
  selectIsAuthenticated,
  selectCurrentUser,
  selectCurrentAdmin,
} from 'features/auth/authSlice';

const UserAppContainer = lazy(() => import('./UserAppContainer'));
const AdminContainer = lazy(() => import('./AdminContainer'));
const NotFound = lazy(() => import('features/404/NotFound'));
const SignInPage = lazy(() => import('features/auth/SignInPage'));
const ForgotPasswordPage = lazy(() =>
  import('features/auth/ForgotPasswordPage')
);
const UpdatePasswordFromCode = lazy(() =>
  import('features/auth/UpdatePasswordFromCodePage')
);
const LoginAsAdmin = lazy(() => import('features/auth/LoginAsAdmin'));

toast.configure({ autoClose: 3500 });

const App: React.FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    let user;
    let isReady = false;
    if (currentUser.id) {
      user = currentUser;
      isReady = true;
    } else if (currentAdmin.id) {
      user = currentAdmin;
      isReady = true;
    } else {
      return;
    }
    if (isAuthenticated && isReady) {
      LogRocket.client().identify(String(user.id), {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }
  }, [isAuthenticated, currentUser, currentAdmin]);

  return (
    <div className="App">
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            key="admin-login"
            path="/admin/login/as_user/:token"
            component={LoginAsAdmin}
          />
          <Route
            exact
            key="admin-login"
            path="/admin/login"
            component={SignInPage}
          />
          <Route
            exact
            key="admin-forgot-password"
            path="/admin/forgot-password"
            component={ForgotPasswordPage}
          />
          <Route
            exact
            key="admin-forgot-password"
            path="/admin/forgot-password/:token"
            component={UpdatePasswordFromCode}
          />

          <Route exact key="login" path="/login" component={SignInPage} />
          <Route
            exact
            key="forgot-password"
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <Route
            exact
            key="forgot-password"
            path="/forgot-password/:token"
            component={UpdatePasswordFromCode}
          />
          <Route
            exact
            key="enrollment"
            path="/enrollment/:token"
            component={EnrollmentPage}
          />

          <PrivateRoute path="/admin">
            <AdminContainer />
          </PrivateRoute>

          <PrivateRoute key="user-container" path="/">
            <UserAppContainer />
          </PrivateRoute>
          <Route component={NotFound}></Route>
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
