import {
  createSlice,
  PayloadAction,
  createSelector,
  Action,
} from '@reduxjs/toolkit';
import { AppThunk, AsyncThunk } from 'app/store';
import WorthyyAPI, {
  Admin,
  TokenResponse,
  UserSignInResponse,
  AdminSignInResponse,
  User,
  AdminUpdateResponse,
  LoginAsUserResponse,
  UserUpdateSelfResponse,
  ConsumeEnrollmentOptions,
  EnrollmentResponse,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface AuthState {
  admin: Admin;
  user: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  errorMessage: string;
}

let initialState: AuthState = {
  user: {} as User,
  admin: {} as Admin,
  isAuthenticated: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: AuthState) {
  state.isLoading = true;
}

function loadingFailed(state: AuthState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    adminSignInStart: startLoading,
    userSignInStart: startLoading,
    adminPasswordResetStart: startLoading,
    userPasswordResetStart: startLoading,
    hydrateUserStart: startLoading,
    hydrateAdminStart: startLoading,
    updateAdminPasswordStart: startLoading,
    updateAdminStart: startLoading,
    loginAsUserStart: startLoading,
    updateUserPasswordStart: startLoading,
    updateCurrentUserStart: startLoading,
    adminPasswordSetFromTokenStart: startLoading,
    userPasswordSetFromTokenStart: startLoading,
    getUserFromEnrollmentTokenStart: startLoading,
    consumeEnrollmentTokenStart: startLoading,

    adminSignInSuccess(state, { payload }: PayloadAction<TokenResponse>) {
      const {
        meta: { token },
      } = payload;
      state.isAuthenticated = true;
      localStorage.setItem('access_token', token);
    },
    userSignInSuccess(state, { payload }: PayloadAction<TokenResponse>) {
      const {
        meta: { token },
      } = payload;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = '';
      localStorage.setItem('access_token', token);
    },
    hydrateUserSuccess(state, { payload }: PayloadAction<UserSignInResponse>) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = '';
      state.user = payload.data;
    },
    hydrateAdminSuccess(
      state,
      { payload }: PayloadAction<AdminSignInResponse>
    ) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.errorMessage = '';
      state.admin = payload.data;
    },
    updateAdminPasswordSuccess(
      state,
      { payload }: PayloadAction<AdminUpdateResponse>
    ) {
      state.admin = payload.data;
    },
    updateAdminSuccess(state, { payload }: PayloadAction<AdminUpdateResponse>) {
      state.admin = payload.data;
    },
    loginAsUserSuccess(state, { payload }: PayloadAction<LoginAsUserResponse>) {
      const {
        data: { token },
      } = payload;

      window.open(`${window.location.origin}/#/admin/login/as_user/${token}`);
    },

    signOut(state, action: Action<any>) {
      localStorage.removeItem('access_token');
    },

    updateUserPasswordSuccess(
      state,
      { payload }: PayloadAction<UserUpdateSelfResponse>
    ) {
      state.isLoading = false;
      state.errorMessage = '';
      state.user = payload.user;
    },

    updateCurrentUserSuccess(
      state,
      { payload }: PayloadAction<UserUpdateSelfResponse>
    ) {
      state.isLoading = false;
      state.errorMessage = '';
      state.user = payload.user;
    },

    adminPasswordSetFromTokenSuccess(state, action: PayloadAction<any>) {},
    userPasswordSetFromTokenSuccess(state, action: PayloadAction<any>) {},
    getUserFromEnrollmentTokenSuccess(
      state,
      action: PayloadAction<EnrollmentResponse>
    ) {
      state.isLoading = false;
    },
    consumeEnrollmentTokenSuccess(state, action) {},

    userPasswordResetSuccess(state, action: PayloadAction<any>) {},
    adminPasswordResetSuccess(state, action: PayloadAction<any>) {},
    adminSignInFailure: loadingFailed,
    userSignInFailure: loadingFailed,
    adminPasswordResetFailure: loadingFailed,
    userPasswordResetFailure: loadingFailed,
    hydrateAdminFailure: loadingFailed,
    updateAdminPasswordFailure: loadingFailed,
    updateAdminFailure: loadingFailed,
    loginAsUserFailure: loadingFailed,
    updateUserPasswordFailure: loadingFailed,

    hydrateUserFailure: loadingFailed,
    updateCurrentUserFailure: loadingFailed,

    adminPasswordSetFromTokenFailure: loadingFailed,
    userPasswordSetFromTokenFailure: loadingFailed,
    getUserFromEnrollmentTokenFailure: loadingFailed,
    consumeEnrollmentTokenFailure: loadingFailed,
  },
});

export const {
  adminSignInStart,
  adminSignInSuccess,
  adminSignInFailure,
  userSignInStart,
  userSignInSuccess,
  userSignInFailure,
  signOut,
  adminPasswordResetStart,
  adminPasswordResetFailure,

  adminPasswordResetSuccess,
  hydrateAdminStart,
  hydrateAdminSuccess,
  hydrateAdminFailure,

  hydrateUserStart,
  hydrateUserSuccess,
  hydrateUserFailure,
  userPasswordResetStart,
  userPasswordResetFailure,
  userPasswordResetSuccess,
  updateUserPasswordStart,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,

  updateAdminStart,
  updateAdminSuccess,
  updateAdminFailure,
  updateAdminPasswordStart,
  updateAdminPasswordSuccess,
  updateAdminPasswordFailure,

  loginAsUserStart,
  loginAsUserSuccess,
  loginAsUserFailure,

  updateCurrentUserStart,
  updateCurrentUserSuccess,
  updateCurrentUserFailure,

  adminPasswordSetFromTokenStart,
  adminPasswordSetFromTokenSuccess,
  adminPasswordSetFromTokenFailure,

  userPasswordSetFromTokenStart,
  userPasswordSetFromTokenSuccess,
  userPasswordSetFromTokenFailure,

  getUserFromEnrollmentTokenStart,
  getUserFromEnrollmentTokenSuccess,
  getUserFromEnrollmentTokenFailure,

  consumeEnrollmentTokenStart,
  consumeEnrollmentTokenFailure,
  consumeEnrollmentTokenSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const adminSignIn = (
  email: string,
  password: string
): AppThunk => async dispatch => {
  try {
    dispatch(adminSignInStart());
    const admin = await WorthyyAPI.admin.signIn(email, password);
    return dispatch(adminSignInSuccess(admin));
  } catch (err) {
    dispatch(adminSignInFailure(err.toString()));
    throw err;
  }
};

export const userSignIn = (
  email: string,
  password: string
): AppThunk => async dispatch => {
  try {
    dispatch(userSignInStart());
    const data = await WorthyyAPI.user.signIn(email, password);
    return dispatch(userSignInSuccess(data));
  } catch (err) {
    dispatch(userSignInFailure(err.toString()));
    throw err;
  }
};

export const hydrateUser = (): AsyncThunk<UserSignInResponse> => async dispatch => {
  try {
    dispatch(hydrateUserStart());
    const data = await WorthyyAPI.user.hydrate();
    return dispatch(hydrateUserSuccess(data));
  } catch (err) {
    dispatch(hydrateUserFailure(err.toString()));
    throw err;
  }
};

export const hydrateAdmin = (): AppThunk => async dispatch => {
  try {
    dispatch(hydrateAdminStart());
    const data = await WorthyyAPI.admin.hydrate();
    return dispatch(hydrateAdminSuccess(data));
  } catch (err) {
    dispatch(hydrateAdminFailure(err.toString()));
    throw err;
  }
};

export const updateAdminPassword = (
  new_password: string
): AppThunk => async dispatch => {
  try {
    dispatch(updateAdminPasswordStart());
    const admin = await WorthyyAPI.admin.updateMe({ new_password });
    return dispatch(updateAdminPasswordSuccess(admin));
  } catch (err) {
    dispatch(updateAdminPasswordFailure(err.toString()));
    throw err;
  }
};

export const updateAdmin = (
  fields: Partial<Admin>
): AppThunk => async dispatch => {
  try {
    dispatch(updateAdminStart());
    const admin = await WorthyyAPI.admin.updateMe(fields);
    return dispatch(updateAdminSuccess(admin));
  } catch (err) {
    dispatch(updateAdminFailure(err.toString()));
    throw err;
  }
};

export const updateCurrentUser = (
  fields: Partial<User>
): AppThunk => async dispatch => {
  try {
    dispatch(updateCurrentUserStart());
    const admin = await WorthyyAPI.user.update(fields);
    return dispatch(updateCurrentUserSuccess(admin));
  } catch (err) {
    dispatch(updateCurrentUserFailure(err.toString()));
    throw err;
  }
};

export const loginAsUser = (
  userId: string | number
): AppThunk => async dispatch => {
  try {
    dispatch(loginAsUserStart());
    const admin = await WorthyyAPI.admin.users.getLoginToken(userId);
    return dispatch(loginAsUserSuccess(admin));
  } catch (err) {
    dispatch(loginAsUserFailure(err.toString()));
    throw err;
  }
};

export const adminPasswordReset = (
  email: string
): AppThunk => async dispatch => {
  try {
    dispatch(adminPasswordResetStart());
    const admin = await WorthyyAPI.admin.password.reset(email);
    return dispatch(adminPasswordResetSuccess(admin));
  } catch (err) {
    dispatch(adminPasswordResetFailure(err.toString()));
    throw err;
  }
};

export const adminPasswordSetFromToken = (
  new_password: string,
  token: string
): AppThunk => async dispatch => {
  try {
    dispatch(adminPasswordSetFromTokenStart());
    const admin = await WorthyyAPI.admin.password.setWithToken(
      new_password,
      token
    );
    return dispatch(adminPasswordSetFromTokenSuccess(admin));
  } catch (err) {
    dispatch(adminPasswordSetFromTokenFailure(err.toString()));
    throw err;
  }
};

export const userPasswordReset = (
  email: string
): AppThunk => async dispatch => {
  try {
    dispatch(userPasswordResetStart());
    const user = await WorthyyAPI.user.password.reset(email);
    return dispatch(userPasswordResetSuccess(user));
  } catch (err) {
    dispatch(userPasswordResetFailure(err.toString()));
    throw err;
  }
};

export const consumeEnrollmentToken = (
  data: ConsumeEnrollmentOptions
): AppThunk => async dispatch => {
  try {
    dispatch(consumeEnrollmentTokenStart());
    const user = await WorthyyAPI.user.enrollment.consume(data);
    return dispatch(consumeEnrollmentTokenSuccess(user));
  } catch (err) {
    dispatch(consumeEnrollmentTokenFailure(err.toString()));
    throw err;
  }
};

export const getUserFromEnrollmentToken = (
  token: string
): AsyncThunk<EnrollmentResponse> => async dispatch => {
  try {
    dispatch(getUserFromEnrollmentTokenStart());
    const data = await WorthyyAPI.user.enrollment.findByToken(token);
    return dispatch(getUserFromEnrollmentTokenSuccess(data));
  } catch (err) {
    dispatch(getUserFromEnrollmentTokenFailure(err.toString()));
    throw err;
  }
};

export const userPasswordSetFromToken = (
  new_password: string,
  token: string
): AppThunk => async dispatch => {
  try {
    dispatch(userPasswordSetFromTokenStart());
    const user = await WorthyyAPI.user.password.setWithToken(
      new_password,
      token
    );
    return dispatch(userPasswordSetFromTokenSuccess(user));
  } catch (err) {
    dispatch(userPasswordSetFromTokenFailure(err.toString()));
    throw err;
  }
};

export const updateUserPasswordSelf = (
  newPassword: string
): AppThunk => async dispatch => {
  try {
    dispatch(updateUserPasswordStart());
    const user = await WorthyyAPI.user.password.update(newPassword);
    return dispatch(updateUserPasswordSuccess(user));
  } catch (err) {
    dispatch(updateUserPasswordFailure(err.toString()));
    throw err;
  }
};

// selectors

export const selectCurrentUser = createSelector(
  (state: RootState) => state.auth.user,
  user => user
);

export const selectCurrentAdmin = createSelector(
  (state: RootState) => state.auth.admin,
  admin => admin
);

export const selectIsAuthenticated = createSelector(
  (state: RootState) => state.auth.isAuthenticated,
  authed => authed
);

export const selectAuthLoading = createSelector(
  (state: RootState) => state.auth.isLoading,
  loading => loading
);

export const selectAuthErrorMessage = createSelector(
  (state: RootState) => state.auth.errorMessage,
  message => message
);
