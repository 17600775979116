import * as yup from 'yup';
import { isAfter } from 'date-fns/esm';
import { addMonths } from 'date-fns';

export const paymentMethodSchema = yup.object().shape({
  zip: yup
    .string()
    .required()
    .test('zip-test', 'Zip code must be 5 digits', (value: string) => {
      if (value && /^\d{5}$/.test(String(value))) {
        return true;
      }
      return false;
    }),
  number: yup
    .string()
    .required()
    .test(
      'number-test',
      'Card number must be at least 15 digits',
      (value: string) => {
        if (value && !/^\d{15,16}$/.test(String(value).replace(/\D/g, ''))) {
          return false;
        }
        return true;
      }
    ),
  cvv: yup
    .string()
    .required()
    .test('cvc-test', 'cvc code must be 3 or 4 digits', (value: string) => {
      if (value && /\d{3,4}/.test(String(value))) {
        return true;
      }
      return false;
    }),
  exp_date: yup
    .string()
    .required()
    .test(
      'exp-date-test',
      'Expiration date must be a valid date and at least one month from now',
      (value: string) => {
        if (!value) {
          return false;
        }
        if (value && value.length !== 6) {
          return false;
        }
        
        let month;
        let year;

        if (value.length === 6) {
          month = Number(value.substr(0, 2));
          year = Number(value.substr(2, 6));
        }

        if (month && year) {
          if (month > 12) {
            return false;
          } else if (
            isAfter(
              new Date(year, month, 1),
              addMonths(new Date(), 1)
            )
          ) {
            return true;
          }
        }

        return false;
      }
    ),
});
