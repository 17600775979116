import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { FieldProps } from 'formik';
import { TextField } from '@material-ui/core';

const NumberFormatField: React.FC<FieldProps & NumberFormatProps> = ({
  field,
  form,
  ...rest
}) => {
  const currentError = form.touched[field.name] && form.errors[field.name];

  return (
    <NumberFormat
      {...rest}
      name={field.name}
      customInput={TextField}
      value={field.value}
      onValueChange={({ value }) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true, false);
      }}
      material="true" // harmless, just passing this along to eliminate warnings about not being a dom property
      onBlur={field.onBlur}
      helperText={currentError}
      error={Boolean(currentError)}
    />
  );
};

export default NumberFormatField;
