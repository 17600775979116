import React, { ReactElement } from 'react';
import { Formik, FormikHelpers, FormikConfig } from 'formik';
import { Button } from '@material-ui/core';

interface WizardState<T> {
  page: number;
  values: T;
}
interface Props<T> {
  children: ReactElement[];
  initialValues: T;
  onSubmit: FormikConfig<T>['onSubmit'];
  disablePrevious?: boolean;
  submitText?: string;
}

class Wizard<FormValues> extends React.Component<
  Props<FormValues>,
  WizardState<FormValues>
> {
  static Page: React.FC<any> = ({ children }): any => children;

  constructor(props: Props<FormValues>) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
    };
  }

  next = (values: FormValues) =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));

  validate = (values: FormValues) => {
    const activePage = (React.Children.toArray(this.props.children)[
      this.state.page
    ] as any) as React.Component<FormikConfig<FormValues>>;
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  validationSchema = (values: FormValues) => {
    const activePage = (React.Children.toArray(this.props.children)[
      this.state.page
    ] as any) as React.Component<FormikConfig<FormValues>>;
    return activePage.props.validationSchema
      ? activePage.props.validationSchema
      : {};
  };

  handleSubmit = (values: FormValues, bag: FormikHelpers<FormValues>) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
  };

  render() {
    const { children, disablePrevious, submitText } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="buttons" style={{ marginTop: 20 }}>
              {page > 0 && !disablePrevious && (
                <Button type="button" onClick={this.previous}>
                  Previous
                </Button>
              )}

              {!isLastPage && (
                <Button
                  variant="contained"
                  {...(disablePrevious ? { fullWidth: true } : {})}
                  color="primary"
                  type="submit"
                >
                  Continue
                </Button>
              )}
              {isLastPage && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {submitText ? submitText : `Submit`}
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default Wizard;
