import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import WorthyyAPI, {
  SalesforceUsersResponse,
  SalesforceUser,
} from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export const adapter = createEntityAdapter({
  selectId: (user: SalesforceUser) => user.Id,
});
const initialState = adapter.getInitialState({
  errorMessage: '',
  loading: false,
  fetched: false,
});

export type AuthorizationsState = typeof initialState;
function startLoading(state: AuthorizationsState) {
  state.loading = true;
}

function loadingFailed(
  state: AuthorizationsState,
  action: PayloadAction<string>
) {
  state.loading = false;
  state.errorMessage = action.payload;
}

const salesforceUsersSlice = createSlice({
  name: 'salesforceUsers',
  initialState,
  reducers: {
    fetchSalesforceUsersStart: startLoading,

    fetchSalesforceUsersSuccess(
      state,
      { payload }: PayloadAction<SalesforceUsersResponse>
    ) {
      const { User } = payload;
      adapter.upsertMany(state, User);
      state.loading = false;
      state.fetched = true;
    },

    fetchSalesforceUsersFailure: loadingFailed,
  },
});

export const {
  fetchSalesforceUsersStart,
  fetchSalesforceUsersSuccess,
  fetchSalesforceUsersFailure,
} = salesforceUsersSlice.actions;

export default salesforceUsersSlice.reducer;

/*
  GET /api/admin/billing/admins/{admin}/authorizations
  POST /api/admin/billing/admins/{admin}/authorizations { grant_type: 'salesforce', identifier }
  GET /api/admin/billing/admins/{admin}/authorizations/{authorization}
  PUT /api/admin/billing/admins/{admin}/authorizations/{authorization} { identifier }
  DELETE /api/admin/billing/admins/{admin}/authorizations/{authorization} (edited) 
  and GET /api/admin/billing/salesforce/users to list users (edited) 
  */

export const fetchSalesforceUsers = (): AppThunk<Promise<
  PayloadAction<SalesforceUsersResponse>
>> => async dispatch => {
  try {
    dispatch(fetchSalesforceUsersStart());
    const data = await WorthyyAPI.admin.salesforce.listUsers();
    return dispatch(fetchSalesforceUsersSuccess(data));
  } catch (err) {
    dispatch(fetchSalesforceUsersFailure(err.toString()));
    throw err;
  }
};

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors((state: RootState) => state.salesforceUsers);

export const selectSalesforceUserIds = (state: RootState) => selectIds(state);
export const selectSalesforceUserEntities = (state: RootState) =>
  selectEntities(state);
export const selectAllSalesforceUsers = (state: RootState) => selectAll(state);
export const selectTotalSalesforceUsers = (state: RootState) =>
  selectTotal(state);

export const selectSalesforceUsersFetched = createSelector(
  (state: RootState) => state.salesforceUsers.fetched,
  fetched => fetched
);

export const selectSalesforceUsersAsAutocompleteOptions = createSelector(
  selectAllSalesforceUsers,
  users => {
    const result = users.map(user => ({
      label: `${user.FirstName} ${user.LastName} (${user.Id})`,
      value: user.Id,
    }));

    return [{ label: 'None', value: null }, ...result];
  }
);
