import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import { ProductPricePoint } from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface PricePointsState {
  item: ProductPricePoint;
  items: ProductPricePoint[];
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

let initialState: PricePointsState = {
  item: {} as ProductPricePoint,
  items: [],
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: PricePointsState) {
  state.isLoading = true;
}

function loadingFailed(state: PricePointsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const pricePointsSlice = createSlice({
  name: 'pricePoints',
  initialState,
  reducers: {
    fetchPricePointsStart: startLoading,

    fetchPricePointsSuccess(
      state,
      { payload }: PayloadAction<{ product_price_points: ProductPricePoint[] }>
    ) {
      state.items = payload.product_price_points;
      state.isFetched = true;
      state.isLoading = false;
    },

    fetchPricePointsFailure: loadingFailed,
  },
});

export const {
  fetchPricePointsStart,
  fetchPricePointsSuccess,
  fetchPricePointsFailure,
} = pricePointsSlice.actions;

export default pricePointsSlice.reducer;

export const fetchPricePoints = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchPricePointsStart());
    // There is a random guzzle error preventing fetching, so we're just hardcoding the response for now
    // const data = await WorthyyAPI.admin.pricePoints.list();
    const hardcodedReturn = {
      product_price_points: [
        { handle: 'five', price: 5 },
        { handle: 'nineteen', price: 19 },
        { handle: 'twenty-four', price: 24 },
        { handle: 'twenty-nine', price: 29 },
        { handle: 'thirty-four', price: 34 },
        { handle: 'thirty-nine', price: 39 },
        { handle: 'forty-four', price: 44 },
        { handle: 'forty-five', price: 45 },
        { handle: 'forty-nine', price: 49 },
      ],
    };
    dispatch(fetchPricePointsSuccess(hardcodedReturn));
  } catch (err) {
    dispatch(fetchPricePointsFailure(err.toString()));
  }
};

// selectors

export const selectPricePoints = createSelector(
  (state: RootState) => state.pricePoints.items,
  items => items
);

export const selectPricePointsLoading = createSelector(
  (state: RootState) => state.pricePoints.isLoading,
  bool => bool
);

export const selectPricePointsFetched = createSelector(
  (state: RootState) => state.pricePoints.isFetched,
  bool => bool
);

export const selectPricePointsAsAutocompleteOptions = createSelector(
  (state: RootState) => state.pricePoints.items,
  pricePoints =>
    pricePoints.map(point => ({
      label: String(point.price),
      value: String(point.price),
    }))
);
