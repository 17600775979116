import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
import WorthyyAPI, { TracksResponse, Track } from 'api/worthyyAPI';
import { RootState } from 'app/rootReducer';

export interface TracksState {
  items: Track[];
  isLoading: boolean;
  isFetched: boolean;
  errorMessage: string;
}

interface ById {
  [key: string]: any;
}
let initialState: TracksState = {
  items: [],
  isFetched: false,
  isLoading: false,
  errorMessage: '',
};

function startLoading(state: TracksState) {
  state.isLoading = true;
}

function loadingFailed(state: TracksState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.errorMessage = action.payload;
}

const paymentsSlice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    fetchTracksStart: startLoading,

    fetchTracksSuccess(state, { payload }: PayloadAction<TracksResponse>) {
      const { data } = payload;
      state.items = data;
      state.isFetched = true;
    },

    fetchTracksFailure: loadingFailed,
  },
});

export const {
  fetchTracksStart,
  fetchTracksSuccess,
  fetchTracksFailure,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;

export const fetchTracks = (
  id: number | string
): AppThunk => async dispatch => {
  try {
    dispatch(fetchTracksStart());
    const tracks = await WorthyyAPI.admin.users.showTracks(id);
    dispatch(fetchTracksSuccess(tracks));
  } catch (err) {
    dispatch(fetchTracksFailure(err.toString()));
  }
};

// selectors

export const selectTracks = createSelector(
  (state: RootState) => state.tracks.items,
  items => items
);

export const selectTracksLoading = createSelector(
  (state: RootState) => state.tracks.isLoading,
  items => items
);
